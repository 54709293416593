var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, Button, IconButton, MenuItem, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import InputDados from '../../../components/input_dados';
import ContainerPainel from '../../../components/container_painel';
import { FONT_SIZE, getAnosReferencia, getFloat, getMascaraMoeda } from '../../../utils';
import { PAGINAS } from '../../../routes';
import { ArrowBackIos, ArrowDropDown, ArrowDropUp, ArrowForwardIos, Close, CloudDownload, Download } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { PaginationModelDefault } from '../../../components/grid';
import { adicionarLancamentoManual, getCombosListar, getFolhasComplementares, removerLancamentoManual, alterarSituacaoFolha as alterarSituacaoFolhaAPI, STATUS_API, getRelatorioFolha, getRelatorioContrato, } from './folhas_complementares.service';
import { BoxPrincipal } from '../../../components/grid_box';
import GridComp from '../../../components/grid';
import CheckIcon from '@mui/icons-material/Check';
import AuthContext from '../../../contexts/auth';
export var FolhasComplementaresListar = function () {
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(true), isLoadingInicio = _b[0], setIsLoadingInicio = _b[1];
    var navigate = useNavigate();
    var _c = useState(), FolhasPagamentoMes = _c[0], setFolhasPagamentoMes = _c[1];
    var _d = useState(), FolhasRegistros = _d[0], setFolhasRegistros = _d[1];
    var _e = useState(), InstituicoesDisponiveis = _e[0], setInstituicoesDisponiveis = _e[1];
    var _f = useState(), RublicasDisponiveis = _f[0], setRublicasDisponiveis = _f[1];
    var _g = useState(), TiposRegimesDisponiveis = _g[0], setTiposRegimesDisponiveis = _g[1];
    var _h = useState(getAnosReferencia()), AnosDisponiveis = _h[0], setAnosDisponiveis = _h[1];
    var _j = useState(), ReeducandosDisponiveis = _j[0], setReeducandosDisponiveis = _j[1];
    var _k = useState(new Date().getFullYear()), BuscaAnoReferencia = _k[0], setBuscaAnoReferencia = _k[1];
    var _l = useState(-1), BuscaInstituicao = _l[0], setBuscaInstituicao = _l[1];
    var _m = useState(-1), BuscaTipoRegime = _m[0], setBuscaTipoRegime = _m[1];
    var _o = useState(-1), BuscaReeducando = _o[0], setBuscaReeducando = _o[1];
    var _p = useState(new Date().getMonth() + 1), BuscaMesReferencia = _p[0], setBuscaMesReferencia = _p[1];
    var _q = useState(0), TotalProventos = _q[0], setTotalProventos = _q[1];
    var _r = useState(0), TotalDescontos = _r[0], setTotalDescontos = _r[1];
    var _s = useState(0), TotalTaxas = _s[0], setTotalTaxas = _s[1];
    var _t = useState(0), TotalLiquido = _t[0], setTotalLiquido = _t[1];
    var _u = useState(0), TotalRegistros = _u[0], setTotalRegistros = _u[1];
    var _v = React.useState(PaginationModelDefault), PaginationModel = _v[0], setPaginationModel = _v[1];
    var _w = useState(false), IsExpanded = _w[0], setIsExpanded = _w[1];
    var _x = useState(null), IsExpandedID = _x[0], setIsExpandedID = _x[1];
    var _y = useState(false), FolhaAdmin = _y[0], setFolhaAdmin = _y[1];
    var _z = useState(0), forceRender = _z[0], setForceRender = _z[1];
    var _0 = useState(false), DESABILITAR_CAMPOS = _0[0], setDESABILITAR_CAMPOS = _0[1];
    var triggerReRender = function () {
        setForceRender(function (prev) { return prev + 1; });
    };
    var _1 = useContext(AuthContext) || { User: null, getAcoesFuncionalidade: function () { return []; }, addMensagem: function (mensagem) { } }, User = _1.User, getAcoesFuncionalidade = _1.getAcoesFuncionalidade, addMensagem = _1.addMensagem;
    var _2 = React.useState(getAcoesFuncionalidade(PAGINAS.FOLHAS_COMPLEMENTARES)), AcoesFuncionalidade = _2[0], setAcoesFuncionalidade = _2[1];
    useEffect(function () {
        //chamado quando a paginação é alterada e no inicio
        carregarDados();
        var virtualScroller = document.querySelector('.MuiDataGrid-virtualScroller');
        if (virtualScroller) {
            virtualScroller.style.overflow = 'visible';
        }
        return function () { };
    }, [PaginationModel]);
    useEffect(function () {
        //chamado quando os filtros são alterados e no inicio
        if (isLoadingInicio) {
            setIsLoadingInicio(false);
        }
        else {
            setPaginationModel(function (prevPaginationModel) {
                return __assign(__assign({}, prevPaginationModel), { page: 0 });
            }); //dispara o useEffect que carrega os dados
        }
        return function () { };
    }, [BuscaAnoReferencia, BuscaMesReferencia, BuscaInstituicao, BuscaTipoRegime, BuscaReeducando]);
    useEffect(function () {
        carregarDadosAuxiliares(); //carrega os combos no inicio
        return function () { };
    }, []);
    var carregarDadosAuxiliares = function () { return __awaiter(void 0, void 0, void 0, function () {
        var AcoesFuncionalidadeAux, FolhaAdminAux, ComboListar, instituicoes, tipos_regimes, rubricas, reeducandos;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    AcoesFuncionalidadeAux = getAcoesFuncionalidade(PAGINAS.FOLHA_ADMIN);
                    FolhaAdminAux = !!AcoesFuncionalidadeAux['VISUALIZAR'];
                    setFolhaAdmin(FolhaAdminAux);
                    return [4 /*yield*/, getCombosListar()];
                case 1:
                    ComboListar = _a.sent();
                    if (ComboListar.Status == STATUS_API.ERRO) {
                        alert(ComboListar.Mensagem);
                    }
                    else {
                        instituicoes = ComboListar.instituicoes, tipos_regimes = ComboListar.tipos_regimes, rubricas = ComboListar.rubricas, reeducandos = ComboListar.reeducandos;
                        setInstituicoesDisponiveis(instituicoes);
                        setTiposRegimesDisponiveis(tipos_regimes);
                        setRublicasDisponiveis(rubricas);
                        setReeducandosDisponiveis(reeducandos);
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Resposta, dados, total_registros, Registros, total_proventos, total_descontos, total_taxas, total_liquido, i, FolhaItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(BuscaAnoReferencia && BuscaMesReferencia && BuscaInstituicao && BuscaInstituicao != -1))
                        return [2 /*return*/];
                    setIsLoading(true);
                    return [4 /*yield*/, getFolhasComplementares(BuscaAnoReferencia.toString(), BuscaMesReferencia.toString(), BuscaInstituicao.toString(), BuscaTipoRegime == -1 ? null : BuscaTipoRegime.toString(), BuscaReeducando == -1 ? null : BuscaReeducando.toString(), PaginationModel)];
                case 1:
                    Resposta = _a.sent();
                    if (Resposta.Status == STATUS_API.ERRO) {
                        alert(Resposta.Mensagem);
                        setFolhasRegistros([]);
                    }
                    else {
                        dados = Resposta.dados, total_registros = Resposta.total_registros;
                        Registros = dados === null || dados === void 0 ? void 0 : dados.registros;
                        setFolhasPagamentoMes(dados);
                        setFolhasRegistros(Registros);
                        setDESABILITAR_CAMPOS((FolhasPagamentoMes === null || FolhasPagamentoMes === void 0 ? void 0 : FolhasPagamentoMes.folha_complementar_situacao) == 'Fechada');
                        total_proventos = 0;
                        total_descontos = 0;
                        total_taxas = 0;
                        total_liquido = 0;
                        for (i = 0; i < Registros.length; i++) {
                            FolhaItem = Registros[i];
                            total_proventos += FolhaItem.proventos;
                            total_descontos += FolhaItem.descontos;
                            total_taxas += FolhaItem.taxas;
                            total_liquido += FolhaItem.liquido;
                        }
                        setTotalProventos(total_proventos);
                        setTotalDescontos(total_descontos);
                        setTotalTaxas(total_taxas);
                        setTotalLiquido(total_liquido);
                        setTotalRegistros(total_registros);
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var columns = [
        {
            field: 'id',
            headerName: 'Código',
            width: 80,
        },
        {
            field: 'nome',
            headerName: 'Nome',
            width: 350,
        },
        { field: 'perfil_laboral', headerName: 'Função', width: 200 },
        { field: 'instituicao.razao_social', headerName: 'Local de Trabalho', width: 200 },
        { field: 'salario', headerName: 'Salário', width: 120 },
        { field: 'proventos', headerName: 'Proventos', width: 120 },
        { field: 'descontos', headerName: 'Descontos', width: 120 },
        { field: 'liquido', headerName: 'Salário Líquido', width: 150 },
        {
            field: 'download',
            headerName: '',
            flex: 0.5,
        },
        {
            field: 'expand',
            headerName: '',
            flex: 0.5,
            sortable: false,
        },
    ];
    var meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    // Funções para navegar pelos meses
    var alterarMes = function (direcao) {
        setBuscaMesReferencia(function (prevMes) {
            var novoMes = prevMes + direcao;
            if (novoMes > 12)
                novoMes = 12;
            if (novoMes < 1)
                novoMes = 1;
            return novoMes;
        });
    };
    var adicionarLancamento = function (contrato_id, rubrica_id, valor) { return __awaiter(void 0, void 0, void 0, function () {
        var Valor, Resp, NovoLancamento, LancAux_1, TipoRubrica;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!contrato_id || !rubrica_id || !valor)
                        return [2 /*return*/];
                    setIsLoading(true);
                    Valor = getFloat(valor);
                    return [4 /*yield*/, adicionarLancamentoManual(contrato_id, rubrica_id, Valor, BuscaAnoReferencia, BuscaMesReferencia)];
                case 1:
                    Resp = _d.sent();
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        carregarDados();
                        NovoLancamento = Resp.dados;
                        LancAux_1 = {
                            id: NovoLancamento.id,
                            nome: (_a = NovoLancamento.rubrica) === null || _a === void 0 ? void 0 : _a.descricao,
                            ref: 'LAN',
                            valor: NovoLancamento.valor,
                            tipo: (_b = NovoLancamento.rubrica) === null || _b === void 0 ? void 0 : _b.tipo_rubrica,
                            manual: true,
                        };
                        TipoRubrica = (_c = NovoLancamento.rubrica) === null || _c === void 0 ? void 0 : _c.tipo_rubrica;
                        setFolhasRegistros(function (prev) {
                            return prev.map(function (Item) {
                                if (Item.id == contrato_id) {
                                    // const Proventos = Item.proventos + (TipoRubrica == 'PROVENTO' ? Valor : 0);
                                    // const Descontos = Item.descontos + (TipoRubrica == 'DESCONTO' ? Valor : 0);
                                    return __assign(__assign({}, Item), { 
                                        // proventos: Proventos,
                                        // descontos: Descontos,
                                        // liquido: Proventos - Descontos,
                                        lancamentos: __spreadArray(__spreadArray([], Item.lancamentos, true), [LancAux_1], false) });
                                }
                                else {
                                    return Item;
                                }
                            });
                        });
                        triggerReRender();
                    }
                    else {
                        alert(Resp ? Resp.Mensagem : 'Erro ao executar a solicitação');
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var removerLancamento = function (contrato_id, lancamento) { return __awaiter(void 0, void 0, void 0, function () {
        var lancamento_id, Resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!confirm('Deseja realmente remover o lançamento?'))
                        return [2 /*return*/];
                    setIsLoading(true);
                    lancamento_id = lancamento.id;
                    return [4 /*yield*/, removerLancamentoManual(lancamento_id)];
                case 1:
                    Resp = _a.sent();
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        carregarDados();
                        setFolhasRegistros(function (prev) {
                            return prev.map(function (Item) {
                                if (Item.id == contrato_id) {
                                    // const Proventos = Item.proventos - (lancamento.tipo == 'PROVENTO' ? lancamento.valor : 0);
                                    // const Descontos = Item.descontos - (lancamento.tipo == 'DESCONTO' ? lancamento.valor : 0);
                                    return __assign(__assign({}, Item), { 
                                        // proventos: Proventos,
                                        // descontos: Descontos,
                                        // liquido: Proventos - Descontos,
                                        lancamentos: Item.lancamentos.filter(function (item) { return item.id != lancamento_id; }) });
                                }
                                else {
                                    return Item;
                                }
                            });
                        });
                        triggerReRender();
                    }
                    else {
                        alert(Resp ? Resp.Mensagem : 'Erro ao executar a solicitação');
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var downloadRelatorioReeducando = function (contrato_id) { return __awaiter(void 0, void 0, void 0, function () {
        var Doc, link;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, getRelatorioContrato(contrato_id.toString(), BuscaAnoReferencia.toString(), BuscaMesReferencia.toString())];
                case 1:
                    Doc = _a.sent();
                    if (Doc) {
                        try {
                            link = document.createElement('a');
                            link.href = Doc;
                            link.download = "relatorio_reeducando_".concat(contrato_id, ".pdf");
                            // Adiciona o link ao documento e dispara o download
                            document.body.appendChild(link);
                            link.click();
                            // Remove o link do documento
                            document.body.removeChild(link);
                            // Libera a URL temporária
                            window.URL.revokeObjectURL(Doc);
                        }
                        catch (error) {
                            console.log('error : ' + error);
                        }
                    }
                    else {
                        alert('Erro ao gerar o pdf');
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var downloadRelatorioFolha = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Doc, link;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, getRelatorioFolha(BuscaAnoReferencia.toString(), BuscaMesReferencia.toString(), BuscaInstituicao && BuscaInstituicao != -1 ? BuscaInstituicao.toString() : null, BuscaTipoRegime && BuscaTipoRegime != -1 ? BuscaTipoRegime.toString() : null, BuscaReeducando && BuscaReeducando != -1 ? BuscaReeducando.toString() : null)];
                case 1:
                    Doc = _a.sent();
                    if (Doc) {
                        try {
                            link = document.createElement('a');
                            link.href = Doc;
                            link.download = "relatorio_folha_".concat(BuscaAnoReferencia, "_").concat(BuscaMesReferencia, ".pdf");
                            // Adiciona o link ao documento e dispara o download
                            document.body.appendChild(link);
                            link.click();
                            // Remove o link do documento
                            document.body.removeChild(link);
                            // Libera a URL temporária
                            window.URL.revokeObjectURL(Doc);
                        }
                        catch (error) {
                            console.log('error : ' + error);
                        }
                    }
                    else {
                        alert('Erro ao gerar o pdf');
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var alterarSituacaoFolha = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, alterarSituacaoFolhaAPI(BuscaAnoReferencia, BuscaMesReferencia, (FolhasPagamentoMes === null || FolhasPagamentoMes === void 0 ? void 0 : FolhasPagamentoMes.folha_complementar_situacao) == 'Aberta' ? 'Fechada' : 'Aberta')];
                case 1:
                    Resp = _a.sent();
                    if ((Resp === null || Resp === void 0 ? void 0 : Resp.Status) == 'OK') {
                        alert('Situação da folha alterada com sucesso');
                        carregarDados();
                    }
                    else {
                        alert(Resp ? Resp.Mensagem : 'Erro ao executar a solicitação');
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var RowGrid = function (props) {
        var _a, _b, _c;
        var _d = useState(false), IsExpandedLocal = _d[0], setIsExpandedLocal = _d[1];
        var row = props.row, id = props.row.id;
        return (_jsxs(Box, __assign({ p: 1, pl: 1, display: 'flex', flexDirection: 'column' }, { children: [_jsxs(Box, __assign({ display: 'flex', flexDirection: 'row' }, { children: [_jsx(Box, __assign({ sx: {
                                width: 80,
                                textAlign: 'center',
                            } }, { children: row.id }), 'codigo'), _jsxs(Box, __assign({ sx: {
                                width: 350,
                            } }, { children: [_jsx(Typography, __assign({ fontWeight: 'bold' }, { children: (_a = row.reeducando) === null || _a === void 0 ? void 0 : _a.nome })), _jsx(Typography, __assign({ variant: 'body2', color: 'textSecondary' }, { children: ((_b = row.regime_reclusao) === null || _b === void 0 ? void 0 : _b.nome) || 'N/A' })), _jsxs(Typography, __assign({ variant: 'body2', fontSize: FONT_SIZE.medium, color: 'textSecondary', m: 0, p: 0, mt: 1, mr: 2 }, { children: [_jsx("span", __assign({ style: { fontWeight: 'bold' } }, { children: "Conta Banc\u00E1ria:" })), " ", row.conta_bancaria || 'N/A'] }))] }), 'nome'), _jsx(Box, __assign({ sx: {
                                width: 200,
                            } }, { children: (_c = row.perfil_laboral) === null || _c === void 0 ? void 0 : _c.nome }), 'perfil_laboral'), _jsx(Box, __assign({ sx: {
                                width: 200,
                            } }, { children: row.instituicao.razao_social }), 'instituicao.razao_social'), _jsx(Box, __assign({ sx: {
                                width: 120,
                            } }, { children: getMascaraMoeda(row.salario) }), 'salario'), _jsx(Box, __assign({ sx: {
                                width: 120,
                            } }, { children: getMascaraMoeda(row.proventos) }), 'proventos'), _jsx(Box, __assign({ sx: {
                                width: 120,
                            } }, { children: getMascaraMoeda(0) }), 'descontos'), _jsx(Box, __assign({ sx: {
                                width: 150,
                            } }, { children: getMascaraMoeda(row.liquido) }), 'liquido'), _jsxs(Box, __assign({ display: 'flex', width: 150 }, { children: [_jsx(Box, { children: _jsx(IconButton, __assign({ onClick: function () {
                                            downloadRelatorioReeducando(row.id);
                                        }, disabled: !FolhaAdmin && !(AcoesFuncionalidade['EXPORTAR PDF'] || AcoesFuncionalidade['IMPRIMIR']) }, { children: _jsx(CloudDownload, {}) })) }, 'download'), _jsx(Box, { children: _jsx(IconButton, __assign({ onClick: function () {
                                            IsExpandedID == id ? setIsExpandedID(null) : setIsExpandedID(id);
                                        } }, { children: IsExpandedID == id ? _jsx(ArrowDropUp, {}) : _jsx(ArrowDropDown, {}) })) }, 'expand')] }))] })), IsExpandedID == id && _jsx(RowExpanded, __assign({}, row))] })));
    };
    var HeaderCellStyle = {
        fontWeight: 'bold',
    };
    var TableCellStyle = {
        padding: 0.5,
        paddingLeft: 1,
    };
    var InputStyle = {
        '& .MuiInputBase-root': {
            height: '30px', // Override para altura personalizada
        },
    };
    var RowExpanded = function (row) {
        var _a, _b;
        var _c = useState(-1), NovoEventoRublicaID = _c[0], setNovoEventoRublicaID = _c[1];
        var _d = useState(), RubricaSelecioanda = _d[0], setRubricaSelecioanda = _d[1];
        var _e = useState(''), NovoEventoValor = _e[0], setNovoEventoValor = _e[1];
        var _f = useState(row.lancamentos), Lancamentos = _f[0], setLancamentos = _f[1];
        // console.log('Lancamentos : ' + JSON.stringify(Lancamentos));
        var setRubrica = function (id) {
            setRubricaSelecioanda(RublicasDisponiveis.find(function (item) { return item.id == id; }));
            setNovoEventoRublicaID(id);
            setNovoEventoValor('');
        };
        return (_jsxs(Box, __assign({ bgcolor: '#f9f9f9', maxWidth: '60%', mt: 2 }, { children: [_jsxs(Table, __assign({ size: 'small' }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: HeaderCellStyle }, { children: "Evento" })), _jsx(TableCell, __assign({ sx: HeaderCellStyle }, { children: "Ref." })), _jsx(TableCell, __assign({ sx: HeaderCellStyle }, { children: "Proventos" })), _jsx(TableCell, __assign({ sx: HeaderCellStyle }, { children: "Descontos" }))] }) }), _jsxs(TableBody, { children: [Lancamentos &&
                                    Lancamentos.map(function (lancamento) { return (_jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: TableCellStyle }, { children: _jsx(InputDados, { value: lancamento.nome, disabled: true, sx: InputStyle }) })), _jsx(TableCell, __assign({ sx: TableCellStyle }, { children: _jsx(InputDados, { value: lancamento.ref, disabled: true, sx: InputStyle }) })), _jsx(TableCell, __assign({ sx: TableCellStyle }, { children: (lancamento === null || lancamento === void 0 ? void 0 : lancamento.tipo) == 'PROVENTO' && _jsx(InputDados, { value: getMascaraMoeda(lancamento.valor), disabled: true, sx: InputStyle }) })), _jsx(TableCell, __assign({ sx: TableCellStyle }, { children: (lancamento === null || lancamento === void 0 ? void 0 : lancamento.tipo) == 'DESCONTO' && _jsx(InputDados, { value: getMascaraMoeda(lancamento.valor), disabled: true, sx: InputStyle }) })), lancamento.manual && (_jsx(TableCell, __assign({ sx: TableCellStyle }, { children: _jsx(IconButton, __assign({ onClick: function () { return removerLancamento(row.id, lancamento); }, style: { padding: 0 }, disabled: DESABILITAR_CAMPOS }, { children: _jsx(Close, { color: 'error' }) })) })))] }, lancamento.id)); }), _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: TableCellStyle }, { children: _jsxs(InputDados, __assign({ select: true, value: NovoEventoRublicaID, onChange: function (e) { return setRubrica(e.target.value); }, sx: InputStyle, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), RublicasDisponiveis &&
                                                        RublicasDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item === null || item === void 0 ? void 0 : item.descricao }), item.id)); })] })) })), _jsx(TableCell, __assign({ sx: TableCellStyle }, { children: _jsx(InputDados, { value: 'LAN', disabled: true, sx: InputStyle }) })), _jsx(TableCell, __assign({ sx: TableCellStyle }, { children: RubricaSelecioanda && ((_a = RubricaSelecioanda === null || RubricaSelecioanda === void 0 ? void 0 : RubricaSelecioanda.tipo_rubrica) === null || _a === void 0 ? void 0 : _a.toUpperCase()) == 'PROVENTO' && (_jsx(InputDados, { id: "novo_provento".concat(row.id), value: NovoEventoValor, onChange: function (e) { return setNovoEventoValor(getMascaraMoeda(e.target.value)); }, sx: InputStyle })) })), _jsx(TableCell, __assign({ sx: TableCellStyle }, { children: RubricaSelecioanda && ((_b = RubricaSelecioanda === null || RubricaSelecioanda === void 0 ? void 0 : RubricaSelecioanda.tipo_rubrica) === null || _b === void 0 ? void 0 : _b.toUpperCase()) == 'DESCONTO' && (_jsx(InputDados, { id: "novo_desconto_".concat(row.id), value: NovoEventoValor, onChange: function (e) { return setNovoEventoValor(getMascaraMoeda(e.target.value)); }, sx: InputStyle })) })), _jsx(TableCell, __assign({ sx: TableCellStyle }, { children: _jsx(IconButton, __assign({ style: { padding: 0 }, onClick: function () { return adicionarLancamento(row.id, NovoEventoRublicaID, NovoEventoValor); }, disabled: DESABILITAR_CAMPOS }, { children: _jsx(CheckIcon, { color: 'primary' }) })) }))] }, "novo_lancamento_".concat(row.id))] })] })), _jsx(Box, __assign({ p: 1 }, { children: _jsxs(Typography, __assign({ mt: 2 }, { children: [_jsx("strong", { children: "L\u00EDquido a receber:" }), " ", getMascaraMoeda(row.liquido)] })) }))] })));
    };
    // const zerarNovoEvento = () => {
    //   setNovoEventoRublicaID(-1);
    //   setNovoEventoProventoValor('');
    //   setNovoEventoDescontoValor('');
    // };
    var MesSelecaoComp = function () { return (_jsxs(Box, __assign({ display: 'flex', alignItems: 'center' }, { children: [_jsx(IconButton, __assign({ onClick: function () { return alterarMes(-1); } }, { children: _jsx(ArrowBackIos, {}) })), _jsx(Typography, __assign({ variant: 'h6' }, { children: "".concat(meses[BuscaMesReferencia - 1], " - ").concat(BuscaAnoReferencia) })), _jsx(IconButton, __assign({ onClick: function () { return alterarMes(1); } }, { children: _jsx(ArrowForwardIos, {}) })), (FolhasPagamentoMes === null || FolhasPagamentoMes === void 0 ? void 0 : FolhasPagamentoMes.folha_complementar_situacao) && (_jsx(IconButton, __assign({ onClick: FolhaAdmin ? alterarSituacaoFolha : function () { } }, { children: _jsxs(Typography, __assign({ variant: 'body2', ml: 1, color: 'textSecondary' }, { children: [FolhasPagamentoMes === null || FolhasPagamentoMes === void 0 ? void 0 : FolhasPagamentoMes.folha_complementar_situacao, _jsx("span", __assign({ role: 'img', "aria-label": 'lock' }, { children: "\uD83D\uDD12" }))] })) })))] }))); };
    return (_jsx(ContainerPainel, __assign({ pagina: PAGINAS.FOLHAS_COMPLEMENTARES }, { children: !isLoadingInicio && (_jsxs(BoxPrincipal, __assign({ width: '90%' }, { children: [_jsxs(Grid, __assign({ container: true, spacing: 2, mb: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 3 }, { children: _jsx(InputDados, __assign({ select: true, id: 'ano_referencia', label: 'Ano de Refer\u00EAncia', placeholder: 'Ano de Refer\u00EAncia', value: BuscaAnoReferencia, onChange: function (event) {
                                    setBuscaAnoReferencia(event.target.value);
                                } }, { children: AnosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.value }, { children: item.label }), item.value)); }) })) })), _jsx(Grid, __assign({ item: true, xs: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'instituicao', label: 'Institui\u00E7\u00E3o', placeholder: 'Institui\u00E7\u00E3o', value: BuscaInstituicao, onChange: function (event) {
                                    setBuscaInstituicao(event.target.value);
                                } }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), InstituicoesDisponiveis &&
                                        InstituicoesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item === null || item === void 0 ? void 0 : item.dados.razao_social }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'regime_reclusao', label: 'Tipo de Regime', placeholder: 'Tipo de Regime', value: BuscaTipoRegime, onChange: function (event) {
                                    setBuscaTipoRegime(event.target.value);
                                } }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), TiposRegimesDisponiveis &&
                                        TiposRegimesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item === null || item === void 0 ? void 0 : item.nome }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'reeducando', label: 'Reeducando', placeholder: 'Reeducando', value: BuscaReeducando, onChange: function (event) {
                                    setBuscaReeducando(event.target.value);
                                } }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), ReeducandosDisponiveis &&
                                        ReeducandosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item === null || item === void 0 ? void 0 : item.nome }), item.id)); })] })) }))] })), BuscaInstituicao && BuscaInstituicao != -1 && (FolhasPagamentoMes === null || FolhasPagamentoMes === void 0 ? void 0 : FolhasPagamentoMes.folha_complementar_calculada) ? (_jsx(_Fragment, { children: _jsxs(Box, __assign({ p: 2 }, { children: [_jsxs(Box, __assign({ sx: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    backgroundColor: '#f9f9f9',
                                    padding: 2,
                                    borderRadius: 1,
                                } }, { children: [_jsx(Typography, __assign({ variant: 'subtitle1', fontWeight: 'bold' }, { children: "Totais do empregador:" })), _jsxs(Grid, __assign({ container: true, spacing: 2, justifyContent: 'flex-end' }, { children: [_jsxs(Grid, __assign({ item: true }, { children: [_jsx(Typography, __assign({ variant: 'body2', color: 'textSecondary' }, { children: "Total proventos:" })), _jsx(Typography, __assign({ variant: 'body1', color: 'green' }, { children: getMascaraMoeda(TotalProventos) }))] })), _jsxs(Grid, __assign({ item: true }, { children: [_jsx(Typography, __assign({ variant: 'body2', color: 'textSecondary' }, { children: "Total descontos:" })), _jsx(Typography, __assign({ variant: 'body1', color: 'error' }, { children: TotalDescontos ? getMascaraMoeda(TotalDescontos) : 0 }))] })), _jsxs(Grid, __assign({ item: true }, { children: [_jsx(Typography, __assign({ variant: 'body2', color: 'textSecondary' }, { children: "Total taxas:" })), _jsx(Typography, __assign({ variant: 'body1', sx: { color: '#FFA500' } }, { children: TotalTaxas ? getMascaraMoeda(TotalTaxas) : 'R$ 0,00' }))] })), _jsxs(Grid, __assign({ item: true }, { children: [_jsx(Typography, __assign({ variant: 'body2', color: 'textSecondary' }, { children: "Total l\u00EDquido:" })), _jsx(Typography, __assign({ variant: 'body1', color: 'primary' }, { children: getMascaraMoeda(TotalLiquido) }))] }))] }))] })), _jsxs(Box, __assign({ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }, { children: [_jsx(MesSelecaoComp, {}), _jsx(Button, __assign({ variant: 'contained', color: 'success', startIcon: _jsx(Download, {}), onClick: downloadRelatorioFolha, disabled: !FolhaAdmin && !(AcoesFuncionalidade['EXPORTAR PDF'] || AcoesFuncionalidade['IMPRIMIR']) }, { children: "Gerar relat\u00F3rio" }))] })), _jsx(GridComp, { rows: FolhasRegistros, columns: columns, 
                                // pageSize={5}
                                disableColumnMenu: true, 
                                // disableSelectionOnClick
                                autoHeight: true, getRowHeight: function (params) {
                                    return IsExpandedID ? 300 : 112;
                                }, TotalRegistros: TotalRegistros, isLoading: isLoading, PaginationModel: PaginationModel, setPaginationModel: setPaginationModel, slots: {
                                    row: function (props) {
                                        var id = props.id, row = props.row;
                                        return (_jsx(Box, __assign({ sx: {
                                                borderBottom: '1px solid #ddd',
                                            } }, { children: _jsx(RowGrid, __assign({}, props)) }), id));
                                    },
                                } }, forceRender)] })) })) : (_jsx(_Fragment, { children: _jsxs(Box, __assign({ p: 2, pt: 5, mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', sx: { backgroundColor: '#f9f9f9', borderRadius: 2, textAlign: 'center', padding: 4 } }, { children: [_jsx(MesSelecaoComp, {}), _jsx(Typography, __assign({ variant: 'body1', gutterBottom: true, mt: 3 }, { children: !BuscaInstituicao || BuscaInstituicao == -1
                                    ? 'Selecione uma instituição para visualizar os dados'
                                    : 'A folha complementar referente a este período ainda não foi calculada.' })), _jsx(Button, __assign({ variant: 'contained', color: 'success', onClick: alterarSituacaoFolha, sx: { textTransform: 'none', padding: '8px 16px', marginTop: 5 }, disabled: !FolhaAdmin || !BuscaInstituicao || BuscaInstituicao == -1 || !FolhasPagamentoMes }, { children: "Calcular folha complementar" }))] })) }))] }))) })));
};
export default FolhasComplementaresListar;
