var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { getDataFormatada, ExportarExcel, ExportarPDF } from '../../../utils';
import ContainerPainel from '../../../components/container_painel';
import AuthContext from '../../../contexts/auth';
import { ROUTES, PAGINAS } from '../../../routes';
import { BotaoAdicionar } from '../../../components/botoes';
import BarraPesquisar from '../../../components/grid_pesquisar';
import GridComp from '../../../components/grid';
import { PaginationModelDefault } from '../../../components/grid';
import { BoxPrincipal, BoxSuperior, BoxGrid } from '../../../components/grid_box';
import { getAtendimentos, getCombosListar, gerarExcelAtendimentos, gerarPDFAtendimentos } from './atendimentos_identificacao_civil.service';
import { STATUS_API } from '../../../api';
import { useNavigate } from 'react-router-dom';
export var AtendimentosIdentificacaoCivilListar = function (props) {
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(true), isLoadingInicio = _b[0], setIsLoadingInicio = _b[1];
    var navigate = useNavigate();
    var _c = useState([]), UsuariosDisponiveis = _c[0], setUsuariosDisponiveis = _c[1];
    var _d = useState(-1), BuscaAtendenteSelecionado = _d[0], setBuscaAtendenteSelecionado = _d[1];
    var _e = useState([]), SituacoesDisponiveis = _e[0], setSituacoesDisponiveis = _e[1];
    var _f = useState('-1'), BuscaSituacaoSelecionada = _f[0], setBuscaSituacaoSelecionada = _f[1];
    var _g = useState(''), BuscaDataInicio = _g[0], setBuscaDataInicio = _g[1];
    var _h = useState(''), BuscaDataFim = _h[0], setBuscaDataFim = _h[1];
    var _j = useState(''), TextoBusca = _j[0], setTextoBusca = _j[1];
    var _k = useState([]), OfertasVagaTrabalhos = _k[0], setOfertasVagaTrabalhos = _k[1];
    var _l = useState(0), TotalRegistros = _l[0], setTotalRegistros = _l[1];
    var _m = React.useState(PaginationModelDefault), PaginationModel = _m[0], setPaginationModel = _m[1];
    var _o = useState(false), AtendenteAdmin = _o[0], setAtendenteAdmin = _o[1];
    var _p = useContext(AuthContext) || { User: null, getAcoesFuncionalidade: function () { return []; } }, User = _p.User, getAcoesFuncionalidade = _p.getAcoesFuncionalidade;
    var _q = React.useState(getAcoesFuncionalidade(PAGINAS.ATENDIMENTOS_IDENTIFICACAO_CIVIL)), AcoesFuncionalidade = _q[0], setAcoesFuncionalidade = _q[1];
    useEffect(function () {
        carregarDadosAuxiliares(); //carrega os combos no inicio
        return function () { };
    }, []);
    useEffect(function () {
        //chamado quando a paginação é alterada e no inicio
        carregarDados();
        return function () { };
    }, [PaginationModel]);
    useEffect(function () {
        //chamado quando os filtros são alterados e no inicio
        if (isLoadingInicio) {
            setIsLoadingInicio(false);
        }
        else {
            setPaginationModel(function (prevPaginationModel) {
                return __assign(__assign({}, prevPaginationModel), { page: 0 });
            }); //dispara o useEffect que carrega os dados
        }
        return function () { };
    }, [TextoBusca]);
    var carregarDadosAuxiliares = function () { return __awaiter(void 0, void 0, void 0, function () {
        var CombosAtendimentos, usuarios, situacoes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCombosListar()];
                case 1:
                    CombosAtendimentos = _a.sent();
                    if (CombosAtendimentos.Status == STATUS_API.ERRO) {
                        alert(CombosAtendimentos.Mensagem);
                        setIsLoading(false);
                        return [2 /*return*/];
                    }
                    usuarios = CombosAtendimentos.usuarios, situacoes = CombosAtendimentos.situacoes;
                    setSituacoesDisponiveis(situacoes);
                    setUsuariosDisponiveis(usuarios);
                    return [2 /*return*/];
            }
        });
    }); };
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var AcoesFuncionalidadeAux, AtendenteAdminAux, BuscaAtendenteSelecionadoParm, Resposta, dados, total_registros;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    AcoesFuncionalidadeAux = getAcoesFuncionalidade(PAGINAS.ATENDIMENTOS_ADMIN);
                    AtendenteAdminAux = !!AcoesFuncionalidadeAux['VISUALIZAR'];
                    setAtendenteAdmin(AtendenteAdminAux);
                    BuscaAtendenteSelecionadoParm = AtendenteAdminAux ? BuscaAtendenteSelecionado : User === null || User === void 0 ? void 0 : User.id;
                    return [4 /*yield*/, getAtendimentos(TextoBusca == '' ? null : TextoBusca, BuscaSituacaoSelecionada == '-1' ? null : BuscaSituacaoSelecionada, BuscaAtendenteSelecionadoParm == -1 ? null : BuscaAtendenteSelecionadoParm === null || BuscaAtendenteSelecionadoParm === void 0 ? void 0 : BuscaAtendenteSelecionadoParm.toString(), BuscaDataInicio == '' ? null : BuscaDataInicio, BuscaDataFim == '' ? null : BuscaDataFim, PaginationModel)];
                case 1:
                    Resposta = _a.sent();
                    if (Resposta.Status == STATUS_API.ERRO) {
                        alert(Resposta.Mensagem);
                    }
                    else {
                        dados = Resposta.dados, total_registros = Resposta.total_registros;
                        setOfertasVagaTrabalhos(dados);
                        setTotalRegistros(total_registros);
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var COLUNAS_FUNCIONALIDADE = [
        {
            field: 'id',
            headerName: 'Código',
            width: 100,
        },
        {
            field: 'data',
            headerName: 'Data',
            width: 100,
            valueGetter: function (value, row) { return getDataFormatada(row.data); },
        },
        {
            field: 'reeducando',
            headerName: 'Egresso',
            width: 200,
            valueGetter: function (value, row) { var _a; return (_a = row.reeducando) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'atendente',
            headerName: 'Atendente',
            width: 200,
            valueGetter: function (value, row) { var _a; return (_a = row.atendente) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'orgao',
            headerName: 'Local de Atendimento',
            width: 200,
            valueGetter: function (value, row) { var _a; return (_a = row.orgao) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'tipo_atendimento',
            headerName: 'Tipo de Atendimento',
            width: 200,
            valueGetter: function (value, row) { var _a; return (_a = row.tipo_atendimento) === null || _a === void 0 ? void 0 : _a.nome; },
        },
        {
            field: 'situacao',
            headerName: 'Situação',
            width: 150,
        },
    ];
    var OPCOES_FUNCIONALIDADE = {
        ROTA: ROUTES.ATENDIMENTOS_IDENTIFICACAO_CIVIL,
        VISUALIZAR: AcoesFuncionalidade.VISUALIZAR,
        ALTERAR: AcoesFuncionalidade.ALTERAR,
        EXCLUIR: AcoesFuncionalidade.EXCLUIR,
    };
    var baixarPDF = function () { return __awaiter(void 0, void 0, void 0, function () {
        var BuscaAtendenteSelecionadoParm, Doc, link;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    BuscaAtendenteSelecionadoParm = AtendenteAdmin ? BuscaAtendenteSelecionado : User === null || User === void 0 ? void 0 : User.id;
                    return [4 /*yield*/, gerarPDFAtendimentos(TextoBusca == '' ? null : TextoBusca, BuscaSituacaoSelecionada == '-1' ? null : BuscaSituacaoSelecionada, BuscaAtendenteSelecionadoParm == -1 ? null : BuscaAtendenteSelecionadoParm === null || BuscaAtendenteSelecionadoParm === void 0 ? void 0 : BuscaAtendenteSelecionadoParm.toString(), BuscaDataInicio == '' ? null : BuscaDataInicio, BuscaDataFim == '' ? null : BuscaDataFim)];
                case 1:
                    Doc = _a.sent();
                    if (Doc) {
                        try {
                            link = document.createElement('a');
                            link.href = Doc;
                            link.download = "atendimentos.pdf";
                            // Adiciona o link ao documento e dispara o download
                            document.body.appendChild(link);
                            link.click();
                            // Remove o link do documento
                            document.body.removeChild(link);
                            // Libera a URL temporária
                            window.URL.revokeObjectURL(Doc);
                        }
                        catch (error) {
                            console.log('error : ' + error);
                        }
                    }
                    else {
                        alert('Erro ao gerar o pdf');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var baixarExcel = function () { return __awaiter(void 0, void 0, void 0, function () {
        var BuscaAtendenteSelecionadoParm, Doc, link;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    BuscaAtendenteSelecionadoParm = AtendenteAdmin ? BuscaAtendenteSelecionado : User === null || User === void 0 ? void 0 : User.id;
                    return [4 /*yield*/, gerarExcelAtendimentos(TextoBusca == '' ? null : TextoBusca, BuscaSituacaoSelecionada == '-1' ? null : BuscaSituacaoSelecionada, BuscaAtendenteSelecionadoParm == -1 ? null : BuscaAtendenteSelecionadoParm === null || BuscaAtendenteSelecionadoParm === void 0 ? void 0 : BuscaAtendenteSelecionadoParm.toString(), BuscaDataInicio == '' ? null : BuscaDataInicio, BuscaDataFim == '' ? null : BuscaDataFim)];
                case 1:
                    Doc = _a.sent();
                    if (Doc) {
                        try {
                            link = document.createElement('a');
                            link.href = Doc;
                            link.download = "atendimentos.xls";
                            // Adiciona o link ao documento e dispara o download
                            document.body.appendChild(link);
                            link.click();
                            // Remove o link do documento
                            document.body.removeChild(link);
                            // Libera a URL temporária
                            window.URL.revokeObjectURL(Doc);
                        }
                        catch (error) {
                            console.log('error : ' + error);
                        }
                    }
                    else {
                        alert('Erro ao gerar o excel');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(ContainerPainel, __assign({ pagina: PAGINAS.ATENDIMENTOS_IDENTIFICACAO_CIVIL, pagina_especifica_permissao: 'Atendimentos - Identifica\u00E7\u00E3o Civil' }, { children: _jsxs(BoxPrincipal, { children: [_jsxs(BoxSuperior, { children: [_jsx(BarraPesquisar, { filtrarGrid: setTextoBusca }), _jsx(BotaoAdicionar, { pagina: ROUTES.ATENDIMENTOS_IDENTIFICACAO_CIVIL, disabled: !AcoesFuncionalidade['CADASTRAR'] }), AcoesFuncionalidade['EXPORTAR PDF'] && _jsx(ExportarPDF, { onClick: baixarPDF }), AcoesFuncionalidade['EXPORTAR EXCEL'] && _jsx(ExportarExcel, { onClick: baixarExcel })] }), _jsx(BoxGrid, { children: _jsx(GridComp, { columns: COLUNAS_FUNCIONALIDADE, rows: OfertasVagaTrabalhos, OPCOES_FUNCIONALIDADE: OPCOES_FUNCIONALIDADE, TotalRegistros: TotalRegistros, isLoading: isLoading, PaginationModel: PaginationModel, setPaginationModel: setPaginationModel, initialState: {
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'asc' }],
                            },
                        } }) })] }) })));
};
export default AtendimentosIdentificacaoCivilListar;
