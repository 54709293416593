var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Box, MenuItem, Grid } from '@mui/material';
import InputDados from '../input_dados';
var StatusPesquisar = function (props) {
    var StatusPesquisa = props.StatusPesquisa, filtrarGrid = props.filtrarGrid, setStatusPesquisa = props.setStatusPesquisa, rest = __rest(props, ["StatusPesquisa", "filtrarGrid", "setStatusPesquisa"]);
    return (_jsx(Box, __assign({ p: 2, pl: 0, sx: { width: 150 } }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao' // obrigatorio
            , label: 'Situa\u00E7\u00E3o' // obrigatorio
            , placeholder: 'Situa\u00E7\u00E3o', value: StatusPesquisa, onChange: function (event) {
                filtrarGrid ? filtrarGrid(null, event.target.value) : setStatusPesquisa ? setStatusPesquisa(event.target.value) : {};
            } }, rest, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), _jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) })));
};
export var StatusPesquisarGrid = function (props) {
    var StatusPesquisa = props.StatusPesquisa, setStatusPesquisa = props.setStatusPesquisa, ColunasGrid = props.ColunasGrid, rest = __rest(props, ["StatusPesquisa", "setStatusPesquisa", "ColunasGrid"]);
    return (_jsx(Grid, __assign({ item: true, xs: ColunasGrid !== null && ColunasGrid !== void 0 ? ColunasGrid : 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao' // obrigatorio
            , label: 'Situa\u00E7\u00E3o' // obrigatorio
            , placeholder: 'Situa\u00E7\u00E3o', value: StatusPesquisa, onChange: function (event) {
                setStatusPesquisa ? setStatusPesquisa(event.target.value) : {};
            } }, rest, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), _jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) })));
};
export default StatusPesquisar;
