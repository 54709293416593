var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { Box, IconButton, MenuItem, Tooltip } from '@mui/material';
import { getCPFFormatado } from '../../../utils';
import ContainerPainel from '../../../components/container_painel';
import AuthContext from '../../../contexts/auth';
import { ROUTES, PAGINAS } from '../../../routes';
import { BotaoAdicionar } from '../../../components/botoes';
import BarraPesquisar from '../../../components/grid_pesquisar';
import Grid from '../../../components/grid';
import { PaginationModelDefault } from '../../../components/grid';
import { BoxPrincipal, BoxSuperior, BoxGrid } from '../../../components/grid_box';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { alterarStatusReeducando, getReeducandos, getTiposRegimes, STATUS_API } from './reeducandos_service';
import InputDados from '../../../components/input_dados';
import StatusPesquisar from '../../../components/status_pesquisar';
import { useNavigate } from 'react-router-dom';
export var ReeducandosListar = function (props) {
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(''), TextoBusca = _b[0], setTextoBusca = _b[1];
    var _c = useState(null), TipoRegimeBusca = _c[0], setTipoRegimeBusca = _c[1];
    var _d = useState(null), StatusBusca = _d[0], setStatusBusca = _d[1];
    var _e = useState(null), DisponibilidadeContratacaoBusca = _e[0], setDisponibilidadeContratacaoBusca = _e[1];
    var _f = useState(null), TiposRegimes = _f[0], setTiposRegimes = _f[1];
    var _g = useState([]), Reeducandos = _g[0], setReeducandos = _g[1];
    var _h = useState(0), TotalRegistros = _h[0], setTotalRegistros = _h[1];
    var _j = React.useState(PaginationModelDefault), PaginationModel = _j[0], setPaginationModel = _j[1];
    var navigate = useNavigate();
    var getAcoesFuncionalidade = (useContext(AuthContext) || { getAcoesFuncionalidade: function () { return []; } }).getAcoesFuncionalidade;
    var _k = React.useState(getAcoesFuncionalidade(PAGINAS.REEDUCANDOS)), AcoesFuncionalidade = _k[0], setAcoesFuncionalidade = _k[1];
    useEffect(function () {
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function (TextoBuscaParm, StatusBuscaParm, TipoRegimeParm, DisponibilidadeContratacaoParm, PaginationModelParm) {
        if (TextoBuscaParm === void 0) { TextoBuscaParm = TextoBusca; }
        if (StatusBuscaParm === void 0) { StatusBuscaParm = StatusBusca; }
        if (TipoRegimeParm === void 0) { TipoRegimeParm = TipoRegimeBusca; }
        if (DisponibilidadeContratacaoParm === void 0) { DisponibilidadeContratacaoParm = DisponibilidadeContratacaoBusca; }
        if (PaginationModelParm === void 0) { PaginationModelParm = PaginationModel; }
        return __awaiter(void 0, void 0, void 0, function () {
            var Resposta, TiposRegimesAux, dados, total_registros;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getReeducandos(TextoBuscaParm == '' ? null : TextoBuscaParm, StatusBuscaParm == '-1' ? null : StatusBuscaParm, TipoRegimeParm == '-1' ? null : TipoRegimeParm, DisponibilidadeContratacaoParm, PaginationModelParm)];
                    case 1:
                        Resposta = _a.sent();
                        if (!!TiposRegimes) return [3 /*break*/, 3];
                        return [4 /*yield*/, getTiposRegimes()];
                    case 2:
                        TiposRegimesAux = _a.sent();
                        if (TiposRegimesAux.Status == STATUS_API.ERRO) {
                            alert(TiposRegimesAux.Mensagem);
                        }
                        else {
                            setTiposRegimes(TiposRegimesAux);
                        }
                        _a.label = 3;
                    case 3:
                        //console.log('PerfisAux', JSON.stringify(PerfisAux));
                        if (Resposta.Status == STATUS_API.ERRO) {
                            alert(Resposta.Mensagem);
                        }
                        else {
                            dados = Resposta.dados, total_registros = Resposta.total_registros;
                            setReeducandos(dados);
                            setTotalRegistros(total_registros);
                        }
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    var filtrarGrid = function (Texto, Status, TipoRegime, DisponibilidadeContratacao) {
        if (Texto === void 0) { Texto = TextoBusca; }
        if (Status === void 0) { Status = StatusBusca; }
        if (TipoRegime === void 0) { TipoRegime = TipoRegimeBusca; }
        if (DisponibilidadeContratacao === void 0) { DisponibilidadeContratacao = DisponibilidadeContratacaoBusca; }
        setTextoBusca(Texto);
        setStatusBusca(Status);
        setTipoRegimeBusca(TipoRegime);
        setDisponibilidadeContratacaoBusca(DisponibilidadeContratacao);
        resetGrid(Texto, Status, TipoRegime, DisponibilidadeContratacao, PaginationModel);
    };
    var onPaginationModelChange = function (PaginationModelParm) {
        //console.log('PaginationModelParm : ' + JSON.stringify(PaginationModelParm));
        setPaginationModel(PaginationModelParm);
        carregarDados(TextoBusca, StatusBusca, TipoRegimeBusca, DisponibilidadeContratacaoBusca, PaginationModelParm);
    };
    var resetGrid = function (Texto, Status, TipoRegime, DisponibilidadeContratacao, PaginationModelParm) {
        var PaginationModelAux = PaginationModelParm !== null && PaginationModelParm !== void 0 ? PaginationModelParm : PaginationModel;
        PaginationModelAux = __assign(__assign({}, PaginationModelAux), { page: 0 });
        setPaginationModel(PaginationModelAux);
        carregarDados(Texto, Status, TipoRegime, DisponibilidadeContratacao, PaginationModelAux);
        //console.log('PaginationModelParm : ' + JSON.stringify(Pagination
    };
    var COLUNAS_FUNCIONALIDADE = [
        {
            field: 'id',
            headerName: 'Código',
            width: 150,
            valueGetter: function (value, row) { return "FUNAC - ".concat(row.id); },
        },
        {
            field: 'nome',
            headerName: 'Nome',
            width: 300,
        },
        {
            field: 'cpf',
            headerName: 'CPF',
            width: 150,
            valueGetter: function (value, row) { return "".concat(getCPFFormatado(value)); },
        },
        {
            field: 'regime_pena',
            headerName: 'Tipo de Regime',
            width: 150,
            valueGetter: function (value, row) { var _a; return "".concat((_a = row.regime_reclusao_atual) === null || _a === void 0 ? void 0 : _a.nome); },
        },
        {
            field: 'disponivel_para_contratacao',
            headerName: 'Disponível para Contratação',
            width: 250,
            valueGetter: function (value, row) { return "".concat(row.disponivel_para_contratacao ? 'Sim' : 'Não'); },
        },
        {
            field: 'situacao',
            headerName: 'Situação',
            //description: 'Responsável',
            width: 100,
            //valueGetter: (value, row) => `${row.status == 'Aitvo' || ''} ${row.descricao || ''}`,
        },
    ];
    var OPCOES_FUNCIONALIDADE = {
        ROTA: ROUTES.REEDUCANDOS,
        VISUALIZAR: AcoesFuncionalidade['VISUALIZAR'],
        ALTERAR: AcoesFuncionalidade['ALTERAR'],
        //EXCLUIR: AcoesFuncionalidade['EXCLUIR'],
    };
    var alterarStatus = function (id, situacao) { return __awaiter(void 0, void 0, void 0, function () {
        var Resposta;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, alterarStatusReeducando(id.toString(), situacao)];
                case 1:
                    Resposta = _a.sent();
                    if (Resposta.Status == STATUS_API.ERRO) {
                        alert(Resposta.Mensagem);
                    }
                    else {
                        carregarDados();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var opcaoAtivarDesativar = function (params) {
        var _a = params.row, id = _a.id, nome = _a.nome, situacao = _a.situacao;
        return (_jsx(Tooltip, __assign({ title: situacao == 'Ativo' ? 'Desativar Reeducando' : 'Ativar Reeducando', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, __assign({ bgcolor: situacao == 'Ativo' ? 'error.main' : 'terceary.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }, onClick: function () {
                        situacao == 'Ativo'
                            ? confirm("Tem certeza que deseja desativar o recuperando ".concat(nome, "?")) && alterarStatus(id, situacao)
                            : alterarStatus(id, situacao);
                    } }, { children: situacao == 'Ativo' ? (_jsx(CloseOutlinedIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } })) : (_jsx(CheckOutlinedIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } })) })) })) })));
    };
    return (_jsx(ContainerPainel, __assign({ pagina_especifica_permissao: PAGINAS.REEDUCANDOS, pagina: 'Cadastro de Reecrutandos' }, { children: _jsxs(BoxPrincipal, { children: [_jsxs(BoxSuperior, { children: [_jsx(BarraPesquisar, { filtrarGrid: filtrarGrid }), _jsx(BotaoAdicionar, { pagina: ROUTES.REEDUCANDOS, disabled: !AcoesFuncionalidade['CADASTRAR'] })] }), _jsxs(Box, __assign({ sx: { width: 650, display: 'flex', flexDirection: 'row' } }, { children: [_jsx(StatusPesquisar, { StatusPesquisa: StatusBusca, filtrarGrid: filtrarGrid }), _jsx(Box, __assign({ p: 2, pl: 0, sx: { width: 200 } }, { children: _jsxs(InputDados, __assign({ select: true, id: 'tipo_regime' // obrigatorio
                                , label: 'Tipo de Regime' // obrigatorio
                                , placeholder: 'Tipo de Regime', value: TipoRegimeBusca, onChange: function (event) {
                                    filtrarGrid(TextoBusca, StatusBusca, event.target.value);
                                }, size: 'small' }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), TiposRegimes &&
                                        TiposRegimes.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Box, __assign({ p: 2, pl: 0, sx: { width: 280 } }, { children: _jsxs(InputDados, __assign({ select: true, id: 'disponibilidade_contratacao' // obrigatorio
                                , label: 'Disponivel para Contrata\u00E7\u00E3o' // obrigatorio
                                , placeholder: 'Disponivel para Contrata\u00E7\u00E3o', value: DisponibilidadeContratacaoBusca, onChange: function (event) {
                                    var ValorAux = event.target.value;
                                    filtrarGrid(TextoBusca, StatusBusca, TipoRegimeBusca, ValorAux == '-1' ? null : ValorAux == 'true');
                                }, size: 'small' }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), _jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) }))] })), _jsx(BoxGrid, { children: _jsx(Grid, { columns: COLUNAS_FUNCIONALIDADE, rows: Reeducandos, OPCOES_FUNCIONALIDADE: OPCOES_FUNCIONALIDADE, OPCAO_ADICIONAL: opcaoAtivarDesativar, TotalRegistros: TotalRegistros, isLoading: isLoading, PaginationModel: PaginationModel, setPaginationModel: onPaginationModelChange }) })] }) })));
};
export default ReeducandosListar;
