var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*eslint no-useless-escape: 0*/
import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, IconButton, MenuItem, Grid, Paper, Dialog, DialogActions, DialogContent, useMediaQuery, useTheme, Divider, InputBase, } from '@mui/material';
import { MODOS, MENSAGENS, getCPFFormatado, getMesesDoAno, getAnosReferencia, getFloatPorcent, } from '../../../utils';
import { ROUTES, PAGINAS } from '../../../routes';
import ContainerPainel from '../../../components/container_painel';
import AuthContext from '../../../contexts/auth';
import { BoxPrincipal, BoxBotoes } from '../../../components/form_box';
import Loading from '../../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { BotaoSalvar, BotaoFechar, BotaoEditar } from '../../../components/botoes';
import { InputDados } from '../../../components/input_dados';
import { DeleteOutline as DeleteOutlineIcon, } from '@mui/icons-material';
import GridComp from '../../../components/grid';
import { alterarLancamentosFolha, cadastrarParametrosLancamentosFolha, getCombosCadastro, getLancamentosFolha, STATUS_API } from './lancamentos_folhas.service';
import { ReeducandosBusca } from '../../reecrutamentos/reeducandos';
import { Search } from '@mui/icons-material';
import { BoxGrid } from '../../../components/grid_box';
import { getMascaraMoeda, getFloat } from '../../../utils';
export var LancamentosFolhasEditar = function (props) {
    var _a = useContext(AuthContext) || { DEV: false, addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, DEV = _a.DEV, addMensagem = _a.addMensagem, getAcoesFuncionalidade = _a.getAcoesFuncionalidade;
    var _b = React.useState(getAcoesFuncionalidade(PAGINAS.LANCAMENTOS_FOLHAS)), AcoesFuncionalidade = _b[0], setAcoesFuncionalidade = _b[1];
    var navigate = useNavigate();
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(true), LoadingInicio = _d[0], setLoadingInicio = _d[1];
    var _e = useParams(), id = _e.id, modo = _e.modo;
    var _f = useState(), LancamentosFolha = _f[0], setLancamentosFolha = _f[1];
    var _g = useState(), ReeducandosDisponiveis = _g[0], setReeducandosDisponiveis = _g[1];
    var _h = useState(), RubricasDisponiveis = _h[0], setRubricasDisponiveis = _h[1];
    var _j = useState(), TiposLancamentosDisponiveis = _j[0], setTiposLancamentosDisponiveis = _j[1];
    var _k = useState(), Nome = _k[0], setNome = _k[1];
    var _l = useState('-1'), TipoLancamentoSelecionado = _l[0], setTipoLancamentoSelecionado = _l[1];
    var _m = useState('-1'), RubricaSelecionada = _m[0], setRubricaSelecionada = _m[1];
    var _o = useState(), ReeducandoSelecionado = _o[0], setReeducandoSelecionado = _o[1];
    var _p = useState([]), ArrayReeducandosSelecionados = _p[0], setArrayReeducandosSelecionados = _p[1];
    var _q = useState(false), CalculaProporcionalDiasTrabalhados = _q[0], setCalculaProporcionalDiasTrabalhados = _q[1];
    var _r = useState(), Valor = _r[0], setValor = _r[1];
    var _s = useState(), Porcentagem = _s[0], setPorcentagem = _s[1];
    var _t = useState(), MesReferencia = _t[0], setMesReferencia = _t[1];
    var _u = useState(new Date().getFullYear()), AnoReferencia = _u[0], setAnoReferencia = _u[1];
    var _v = useState(false), LancamentoRecorrente = _v[0], setLancamentoRecorrente = _v[1];
    var _w = useState(), RecorrenciaMesInicio = _w[0], setRecorrenciaMesInicio = _w[1];
    var _x = useState(), RecorrenciaMesFim = _x[0], setRecorrenciaMesFim = _x[1];
    var _y = useState(), RecorrenciaIndeterminada = _y[0], setRecorrenciaIndeterminada = _y[1];
    var _z = useState('Ativo'), Situacao = _z[0], setSituacao = _z[1];
    var _0 = useState(false), PopupAbertoReeducando = _0[0], setPopupAbertoReeducando = _0[1];
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var DESABILITAR_CAMPOS = modo == MODOS.VISUALIZAR || modo == MODOS.EXCLUIR;
    useEffect(function () {
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var CombosAux, reeducandos, tipos_lancamentos, rubricas, LancamentosFolhaAux, nome, tipo_lancamento, rubrica_id, reeducandos, valor, porcentagem, mes_referencia, calcular_proporcional, lancamento_recorrente, recorrencia_mes_inico, recorrencia_mes_fim, situacao, ano_referencia, recorrencia_inderteminada;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCombosCadastro()];
                case 1:
                    CombosAux = _a.sent();
                    if (CombosAux.Status == STATUS_API.ERRO) {
                        alert(CombosAux.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        reeducandos = CombosAux.reeducandos, tipos_lancamentos = CombosAux.tipos_lancamentos, rubricas = CombosAux.rubricas;
                        setReeducandosDisponiveis(reeducandos);
                        setTiposLancamentosDisponiveis(tipos_lancamentos);
                        setRubricasDisponiveis(rubricas);
                    }
                    if (!id) return [3 /*break*/, 3];
                    return [4 /*yield*/, getLancamentosFolha(id)];
                case 2:
                    LancamentosFolhaAux = _a.sent();
                    if (LancamentosFolhaAux.Status == STATUS_API.ERRO) {
                        alert(LancamentosFolhaAux.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        nome = LancamentosFolhaAux.nome, tipo_lancamento = LancamentosFolhaAux.tipo_lancamento, rubrica_id = LancamentosFolhaAux.rubrica_id, reeducandos = LancamentosFolhaAux.reeducandos, valor = LancamentosFolhaAux.valor, porcentagem = LancamentosFolhaAux.porcentagem, mes_referencia = LancamentosFolhaAux.mes_referencia, calcular_proporcional = LancamentosFolhaAux.calcular_proporcional, lancamento_recorrente = LancamentosFolhaAux.lancamento_recorrente, recorrencia_mes_inico = LancamentosFolhaAux.recorrencia_mes_inico, recorrencia_mes_fim = LancamentosFolhaAux.recorrencia_mes_fim, situacao = LancamentosFolhaAux.situacao, ano_referencia = LancamentosFolhaAux.ano_referencia, recorrencia_inderteminada = LancamentosFolhaAux.recorrencia_inderteminada;
                        setNome(nome);
                        setTipoLancamentoSelecionado(tipo_lancamento);
                        setRubricaSelecionada(rubrica_id);
                        setArrayReeducandosSelecionados(reeducandos !== null && reeducandos !== void 0 ? reeducandos : []);
                        setValor(getMascaraMoeda(valor));
                        setPorcentagem(porcentagem === null || porcentagem === void 0 ? void 0 : porcentagem.toString());
                        setMesReferencia(mes_referencia);
                        setCalculaProporcionalDiasTrabalhados(calcular_proporcional);
                        setLancamentoRecorrente(lancamento_recorrente);
                        setRecorrenciaMesInicio(recorrencia_mes_inico);
                        setRecorrenciaMesFim(recorrencia_mes_fim);
                        setSituacao(situacao);
                        setLancamentosFolha(LancamentosFolhaAux);
                        setAnoReferencia(ano_referencia);
                        setRecorrenciaIndeterminada(recorrencia_inderteminada);
                    }
                    _a.label = 3;
                case 3:
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var MesesAno = getMesesDoAno();
    var AnosDisponiveis = getAnosReferencia();
    var validarDados = function () {
        if (!Nome || Nome.trim() == '') {
            alert("Nome ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!RubricaSelecionada || RubricaSelecionada == '-1') {
            alert("Rubrica ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        // if (!ReeducandosSelecionados || ReeducandosSelecionados.length < 1) {
        //   alert(`Reeducandos ${MENSAGENS.PREENCHIMENTO_OBRIGATORIO}`);
        //   return false;
        // }
        if (LancamentoRecorrente) {
            if (!RecorrenciaIndeterminada) {
                if (!RecorrenciaMesInicio || RecorrenciaMesInicio == 0) {
                    alert("M\u00EAs de in\u00EDcio da recorr\u00EAncia ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
                    return false;
                }
                if (!RecorrenciaMesFim || RecorrenciaMesFim == 0) {
                    alert("M\u00EAs de fim da recorr\u00EAncia ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
                    return false;
                }
                if (RecorrenciaMesInicio > RecorrenciaMesFim) {
                    alert("M\u00EAs de in\u00EDcio da recorr\u00EAncia deve ser menor que o m\u00EAs de fim da recorr\u00EAncia.");
                    return false;
                }
            }
        }
        else {
            if (!MesReferencia || MesReferencia == 0) {
                alert("M\u00EAs de refer\u00EAncia ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
                return false;
            }
        }
        if (!CalculaProporcionalDiasTrabalhados && (!Valor || Valor.trim() == '')) {
            alert("Valor ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (CalculaProporcionalDiasTrabalhados && (!Porcentagem || Porcentagem.trim() == '')) {
            alert("Porcentagem ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        return true;
    };
    var salvarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Dados, Resp, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!validarDados())
                        return [2 /*return*/];
                    setLoading(true);
                    Dados = {
                        nome: Nome,
                        tipo_lancamento: TipoLancamentoSelecionado,
                        rubrica_id: RubricaSelecionada,
                        reeducandos: ArrayReeducandosSelecionados,
                        valor: Valor ? getFloat(Valor) : null,
                        porcentagem: Porcentagem ? getFloatPorcent(Porcentagem) : null,
                        ano_referencia: parseInt(AnoReferencia.toString()),
                        mes_referencia: MesReferencia ? parseInt(MesReferencia.toString()) : null,
                        calcular_proporcional: CalculaProporcionalDiasTrabalhados,
                        lancamento_recorrente: LancamentoRecorrente,
                        recorrencia_mes_inico: RecorrenciaMesInicio,
                        recorrencia_mes_fim: RecorrenciaMesFim,
                        situacao: Situacao,
                        recorrencia_inderteminada: RecorrenciaIndeterminada,
                    };
                    _a = modo;
                    switch (_a) {
                        case MODOS.CADASTRAR: return [3 /*break*/, 1];
                        case MODOS.ALTERAR: return [3 /*break*/, 3];
                    }
                    return [3 /*break*/, 5];
                case 1: return [4 /*yield*/, cadastrarParametrosLancamentosFolha(Dados)];
                case 2:
                    Resp = _b.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, alterarLancamentosFolha(Dados, id !== null && id !== void 0 ? id : '')];
                case 4:
                    Resp = _b.sent();
                    return [3 /*break*/, 5];
                case 5:
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(modo == MODOS.CADASTRAR ? MENSAGENS.REGISTRO_CADASTRADO_SUCESSO : MENSAGENS.REGISTRO_ALTERADO_SUCESSO);
                        navigate(ROUTES.LANCAMENTOS_FOLHAS);
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var editarRegistro = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("".concat(ROUTES.LANCAMENTOS_FOLHAS, "/").concat(id, "/").concat(MODOS.ALTERAR));
            return [2 /*return*/];
        });
    }); };
    var changePorcentagem = function (value) {
        // Regex para aceitar números com no máximo um ponto ou uma vírgula
        var regex = /^[0-9]+([.,][0-9]*)?$/;
        if ((regex.test(value) && value.length <= 6) || value == '') {
            setPorcentagem(value);
        }
    };
    var adicionarReeducando = function () {
        if (!ReeducandoSelecionado) {
            alert('Selecione um Reecrutando');
            return;
        }
        if (ArrayReeducandosSelecionados.find(function (item) { return item.id == ReeducandoSelecionado.id; })) {
            alert('Reecrutando já adicionado');
            return;
        }
        setArrayReeducandosSelecionados(__spreadArray(__spreadArray([], ArrayReeducandosSelecionados, true), [ReeducandoSelecionado], false));
        setReeducandoSelecionado(null);
    };
    var selecionarReeducando = function (reeducando) {
        setReeducandoSelecionado(reeducando);
        setPopupAbertoReeducando(false);
    };
    var handleClose = function () {
        setPopupAbertoReeducando(false);
    };
    var COLUNAS = [
        {
            field: 'nome',
            headerName: 'Reeducando',
            width: 400,
        },
        {
            field: 'cpf',
            headerName: 'CPF',
            //description: '',
            width: 150,
            valueGetter: function (value, row) { return getCPFFormatado(value); },
        },
        {
            field: 'Opções',
            headerName: '',
            align: 'right',
            flex: 1,
            minWidth: 170,
            renderCell: function (params) { return (_jsx(Box, __assign({ flex: 1, display: 'flex', justifyContent: 'flex-end' }, { children: _jsx(Box, __assign({ bgcolor: 'error.main', style: { borderRadius: '50%', width: 25, height: 25, margin: 4 } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 0, marginTop: -1 }, onClick: function () {
                            setArrayReeducandosSelecionados(function (prev) { return prev.filter(function (item) { return item.id != params.row.id; }); });
                        }, disabled: DESABILITAR_CAMPOS }, { children: _jsx(DeleteOutlineIcon, { fontSize: 'medium', style: { color: 'white', marginBottom: 5, paddingBottom: 5 } }) })) })) }))); },
        },
    ];
    return (_jsx(ContainerPainel, __assign({ modo: modo, pagina_acima: PAGINAS.LANCAMENTOS_FOLHAS, link_pagina_acima: ROUTES.LANCAMENTOS_FOLHAS }, { children: _jsxs(BoxPrincipal, __assign({ sx: { width: '90%' } }, { children: [!LoadingInicio && (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ sx: { mt: 2 } }, { children: [_jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, __assign({ select: true, id: 'ano-referencia', label: 'Ano de refer\u00EAncia', placeholder: 'Ano de refer\u00EAncia', value: AnoReferencia, onChange: function (event) {
                                                    setAnoReferencia(event.target.value);
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: AnosDisponiveis &&
                                                    AnosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.value }, { children: item.label }), item.value)); }) })) })), _jsx(Grid, { item: true, xs: 12, sm: 9 }), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'nome', label: 'Nome', placeholder: 'Nome', value: Nome, onChange: function (event) {
                                                    setNome(event.target.value);
                                                }, required: true, disabled: DESABILITAR_CAMPOS }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'tipo_lancamento', label: 'Tipo de lan\u00E7amento', placeholder: 'Tipo de lan\u00E7amento', value: TipoLancamentoSelecionado, onChange: function (event) {
                                                    var TipoLanc = event.target.value;
                                                    if (TipoLanc == 'INDIVIDUAL' && ArrayReeducandosSelecionados.length > 1) {
                                                        alert('Não é possível selecionar mais de um reeducando para lançamento em grupo. Remova Reeduandos.');
                                                    }
                                                    else {
                                                        setTipoLancamentoSelecionado(event.target.value);
                                                    }
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione" })), TiposLancamentosDisponiveis &&
                                                        TiposLancamentosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'rubrica', label: 'Rubrica', placeholder: 'Rubrica', value: RubricaSelecionada, onChange: function (event) {
                                                    setRubricaSelecionada(event.target.value);
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione" })), RubricasDisponiveis &&
                                                        RubricasDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.descricao }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, __assign({ select: true, id: 'mes_referencia', label: 'M\u00EAs de refer\u00EAncia', placeholder: 'M\u00EAs de refer\u00EAncia', value: MesReferencia, onChange: function (event) {
                                                    setMesReferencia(event.target.value);
                                                }, required: !LancamentoRecorrente, disabled: DESABILITAR_CAMPOS }, { children: MesesAno &&
                                                    MesesAno.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.value }, { children: item.label }), item.value)); }) })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'calculo_proporcional_dias_trabalhandos', label: 'Calcula proporcional dias trabalhados', placeholder: 'Situa\u00E7\u00E3o', value: CalculaProporcionalDiasTrabalhados, onChange: function (event) {
                                                    var CalcAux = event.target.value === 'true';
                                                    if (CalcAux) {
                                                        setValor('');
                                                    }
                                                    else {
                                                        setPorcentagem('');
                                                    }
                                                    setCalculaProporcionalDiasTrabalhados(event.target.value === 'true');
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) })), CalculaProporcionalDiasTrabalhados ? (_jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'porcentagem', label: 'Porcentagem %', placeholder: 'Porcentagem %', value: Porcentagem, onChange: function (event) {
                                                    changePorcentagem(event.target.value);
                                                }, required: true, disabled: DESABILITAR_CAMPOS }) }))) : (_jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, { id: 'valor', label: 'Valor', placeholder: 'Valor', value: Valor, onChange: function (event) {
                                                    setValor(getMascaraMoeda(event.target.value));
                                                }, required: true, disabled: DESABILITAR_CAMPOS }) }))), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao', label: 'Situa\u00E7\u00E3o', placeholder: 'Situa\u00E7\u00E3o', value: Situacao, onChange: function (event) {
                                                    setSituacao(event.target.value);
                                                }, required: true, disabled: true }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) }))] })), _jsx(Divider, __assign({ style: { marginTop: 15, marginBottom: 15, color: 'grey' } }, { children: "REEDUCANDOS" })), _jsxs(Box, { children: [_jsxs(Box, __assign({ style: { display: 'flex', justifyContent: 'space-around' } }, { children: [_jsxs(Paper, __assign({ component: 'form', sx: { p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' } }, { children: [_jsx(InputBase, { sx: { ml: 1, flex: 1 }, placeholder: 'Reeducando', inputProps: { 'aria-label': 'search google maps' }, autoFocus: true, value: ReeducandoSelecionado ? "".concat(ReeducandoSelecionado === null || ReeducandoSelecionado === void 0 ? void 0 : ReeducandoSelecionado.nome, " - CPF: ").concat(getCPFFormatado(ReeducandoSelecionado === null || ReeducandoSelecionado === void 0 ? void 0 : ReeducandoSelecionado.cpf)) : '', disabled: true }), _jsx(IconButton, __assign({ type: 'button', sx: { p: '10px' }, "aria-label": 'search', onClick: function () {
                                                                setPopupAbertoReeducando(true);
                                                            }, disabled: DESABILITAR_CAMPOS ||
                                                                TipoLancamentoSelecionado == '-1' ||
                                                                (TipoLancamentoSelecionado == 'INDIVIDUAL' && ArrayReeducandosSelecionados.length > 0) }, { children: _jsx(Search, {}) }))] })), _jsx(Box, { children: _jsx(Button, __assign({ variant: 'contained', style: { backgroundColor: '#109fef' }, onClick: adicionarReeducando, sx: { marginLeft: 10, marginRight: '30%' }, disabled: DESABILITAR_CAMPOS || !ReeducandoSelecionado }, { children: "Adicionar" })) })] })), _jsx(BoxGrid, { children: _jsx(GridComp, { rows: ArrayReeducandosSelecionados, columns: COLUNAS, loading: loading, paginationMode: 'client', onPaginationModelChange: function () { } }) })] }), _jsx(Divider, __assign({ style: { marginTop: 15, marginBottom: 15, color: 'grey' } }, { children: "RECORR\u00CANCIA" })), _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'lancamento_recorrente', label: 'Lan\u00E7amento recorrente', placeholder: 'Lan\u00E7amento recorrente', value: LancamentoRecorrente, onChange: function (event) {
                                                    var Recorrente = event.target.value === 'true';
                                                    if (!Recorrente) {
                                                        setRecorrenciaMesInicio(null);
                                                        setRecorrenciaMesFim(null);
                                                        setRecorrenciaIndeterminada(false);
                                                    }
                                                    setLancamentoRecorrente(Recorrente);
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) })), LancamentoRecorrente && (_jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'recorrencia_indeterminada', label: 'Recorr\u00EAncia indeterminada', placeholder: 'Recorr\u00EAncia indeterminada', value: RecorrenciaIndeterminada, onChange: function (event) {
                                                    var RecorrenteInd = event.target.value === 'true';
                                                    if (RecorrenteInd) {
                                                        setRecorrenciaMesInicio(null);
                                                        setRecorrenciaMesFim(null);
                                                    }
                                                    setRecorrenciaIndeterminada(RecorrenteInd);
                                                }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) }))), LancamentoRecorrente && !RecorrenciaIndeterminada && (_jsxs(_Fragment, { children: [' ', _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, __assign({ select: true, id: 'recorrencia_mes_inicio', label: 'M\u00EAs de in\u00EDcio da recorr\u00EAncia', placeholder: 'M\u00EAs de in\u00EDcio da recorr\u00EAncia', value: RecorrenciaMesInicio, onChange: function (event) {
                                                            setRecorrenciaMesInicio(parseInt(event.target.value));
                                                        }, required: true, disabled: DESABILITAR_CAMPOS }, { children: MesesAno &&
                                                            MesesAno.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.value }, { children: item.label }), item.value)); }) })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsx(InputDados, __assign({ select: true, id: 'recorrencia_mes_fim', label: 'M\u00EAs de fim da recorr\u00EAncia', placeholder: 'M\u00EAs de fim da recorr\u00EAncia', value: RecorrenciaMesFim, onChange: function (event) {
                                                            setRecorrenciaMesFim(parseInt(event.target.value));
                                                        }, required: true, disabled: DESABILITAR_CAMPOS }, { children: MesesAno &&
                                                            MesesAno.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.value }, { children: item.label }), item.value)); }) })) }))] }))] }))] })), _jsxs(BoxBotoes, { children: [(MODOS.ALTERAR == modo || MODOS.CADASTRAR == modo) && _jsx(BotaoSalvar, { onClick: salvarDados }), MODOS.VISUALIZAR == modo && _jsx(BotaoEditar, { onClick: editarRegistro, disabled: !AcoesFuncionalidade['ALTERAR'] }), _jsx(BotaoFechar, { modo: modo })] }), _jsxs(Dialog, __assign({ open: PopupAbertoReeducando, onClose: handleClose, maxWidth: 'xl' }, { children: [_jsx(DialogContent, __assign({ style: { margin: 0 } }, { children: _jsx(ReeducandosBusca, { selecionarReeducando: selecionarReeducando, DisponibilidadeContratacaoBusca: false }) })), _jsx(DialogActions, __assign({ style: { padding: 20 } }, { children: _jsx(Button, __assign({ onClick: handleClose }, { children: "Voltar" })) }))] }))] })), loading && _jsx(Loading, {})] })) })));
};
export default LancamentosFolhasEditar;
