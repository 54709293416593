var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import axios from 'axios';
import React from 'react';
import CryptoJS from 'crypto-js';
import { Box, IconButton, Tooltip, } from '@mui/material';
import ImagemPDF from '../imagens/pdf_icone.png';
import ImagemExcel from '../imagens/excel_icone.png';
var CustomAxios = axios.create();
var toCamelCase = function (object) {
    var transformedObject = object;
    if (typeof object === 'object' && object !== null) {
        if (object instanceof Array) {
            transformedObject = object.map(toCamelCase);
        }
        else {
            transformedObject = {};
            for (var key in object) {
                if (object[key] !== undefined) {
                    var newKey = key.replace(/(_\w)|(-\w)/g, function (k) { return k[1].toUpperCase(); });
                    transformedObject[newKey] = toCamelCase(object[key]);
                }
            }
        }
    }
    return transformedObject;
};
export var toSnackCase = function (object) {
    var transformedObject = object;
    if (typeof object === 'object' && object !== null) {
        if (object instanceof Array) {
            transformedObject = object.map(toSnackCase);
        }
        else {
            transformedObject = {};
            for (var key in object) {
                if (object[key] !== undefined) {
                    var newKey = key
                        .replace(/\.?([A-Z]+)/g, function (_, y) {
                        return '_' + y.toLowerCase();
                    })
                        .replace(/^_/, '');
                    transformedObject[newKey] = toSnackCase(object[key]);
                }
            }
        }
    }
    return transformedObject;
};
CustomAxios.interceptors.response.use(function (response) {
    response.data = toCamelCase(response.data);
    return response;
}, function (error) {
    return Promise.reject(error);
});
CustomAxios.interceptors.request.use(function (config) {
    config.data = toSnackCase(config.data);
    return config;
}, function (error) {
    return Promise.reject(error);
});
export default CustomAxios;
export function openSidebar() {
    if (typeof window !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
}
export function closeSidebar() {
    if (typeof window !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
}
export function toggleSidebar() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        var slideIn = window.getComputedStyle(document.documentElement).getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        }
        else {
            openSidebar();
        }
    }
}
export var FONT_SIZE = {
    verySmall: 6,
    small: 8,
    smallLarge: 10,
    medium: 12,
    mediumLarge: 14,
    large: 18,
    extraLarge: 20,
};
export var MODOS = {
    LISTAR: 'listar',
    CADASTRAR: 'cadastrar',
    VISUALIZAR: 'visualizar',
    ALTERAR: 'alterar',
    EXCLUIR: 'excluir',
    EXPORTAR_CSV: 'exportar_csv',
    IMPRIMIR: 'imprimir',
    EXPORTAR_PDF: 'exportar_pdf',
};
export var MENSAGENS = {
    EXCLUIR_REGISTRO: 'Tem certeza que deseja remover o registro selecionado? Essa ação não pode ser desfeita. Deseja continuar?',
    REGISTRO_EXCLUIDO: 'Registro excluído com sucesso!',
    REGISTRO_INATIVADO: 'Registro inativado com sucesso!',
    REGISTRO_ATIVADO: 'Registro ativado com sucesso!',
    REGISTRO_CADASTRADO: 'Registro cadastrado com sucesso!',
    ERRO_OPERACAO: 'Erro ao executar a operação.',
    SAIR_TELA: 'Tem certeza que deseja sair da tela?',
    REGISTRO_CADASTRADO_SUCESSO: 'Registro cadastrado com sucesso!',
    REGISTRO_ALTERADO_SUCESSO: 'Registro alterado com sucesso!',
    REGISTRO_EXCLUIDO_SUCESSO: 'Registro excluído com sucesso!',
    PREENCHIMENTO_OBRIGATORIO: 'é de preenchimento obrigatório',
    PREENCHIMENTO_INVALIDO: 'informado é inválido',
    REGISTRO_JA_CADASTRADO: 'inserido já está cadastrado',
    DATA_MAIOR_DATA_ATUAL: 'informada é maior que a data atual',
    DATA_MENOR_DATA_ATUAL: 'informada é menor que a data atual',
};
export var getCPFFormatado = function (CPF) {
    if (CPF === void 0) { CPF = ''; }
    var FormattedCPF = CPF.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    return FormattedCPF;
};
export var getCNPJFormatado = function (CNPJ) {
    if (CNPJ === void 0) { CNPJ = ''; }
    return CNPJ.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
};
export var getTelefoneFormatado = function (Tel) {
    if (Tel === void 0) { Tel = ''; }
    var FormattedTel = Tel.replace(/^(\d{2})(\d{4,5})(\d{4})$/, '($1) $2-$3');
    return FormattedTel;
};
export var getDataFormatada = function (DataISO) {
    if (DataISO === void 0) { DataISO = ''; }
    if (!DataISO || DataISO.indexOf('/') > -1) {
        return DataISO;
    }
    var FormattedData = new Date(DataISO).toLocaleDateString('pt-BR');
    return FormattedData;
};
export var validarCPF = function (strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    strCPF = strCPF.replace(/[^0-9]/g, '');
    if (strCPF == '00000000000')
        return false;
    for (var i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
    if (Resto == 10 || Resto == 11)
        Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)))
        return false;
    Soma = 0;
    for (var i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
    if (Resto == 10 || Resto == 11)
        Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11)))
        return false;
    return true;
};
export var validarCNPJ = function (cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    //CNPJ.replace(/[^0-9]/g, ''),
    if (cnpj == '')
        return false;
    if (cnpj.length != 14)
        return false;
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == '00000000000000' ||
        cnpj == '11111111111111' ||
        cnpj == '22222222222222' ||
        cnpj == '33333333333333' ||
        cnpj == '44444444444444' ||
        cnpj == '55555555555555' ||
        cnpj == '66666666666666' ||
        cnpj == '77777777777777' ||
        cnpj == '88888888888888' ||
        cnpj == '99999999999999')
        return false;
    // Valida DVs
    var tamanho = cnpj.length - 2;
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != parseInt(digitos.charAt(0)))
        return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != parseInt(digitos.charAt(1)))
        return false;
    return true;
};
export var validarData = function (data) {
    // Verifica se o formato está correto
    var regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!regex.test(data)) {
        return false;
    }
    var _a = data.split('/').map(Number), day = _a[0], month = _a[1], year = _a[2];
    var date = new Date(year, month - 1, day);
    // Verifica se a data é válida
    if (date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day) {
        return true;
    }
    return false;
};
export var parseDate = function (dateString) {
    var _a = dateString.split('/').map(Number), day = _a[0], month = _a[1], year = _a[2];
    var date = new Date(year, month - 1, day);
    return date.toISOString();
};
export var verificarDatas = function (dataInicio, dataFim, aceitarIgual) {
    var inicio = parseDate(dataInicio);
    var fim = parseDate(dataFim);
    if (aceitarIgual) {
        if (fim < inicio) {
            return false;
        }
    }
    else {
        if (fim <= inicio) {
            return false;
        }
    }
    return true;
};
export var ucFirstAllWords = function (str) {
    var pieces = str.split(' ');
    for (var i = 0; i < pieces.length; i++) {
        var j = pieces[i].charAt(0).toUpperCase();
        pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(' ');
};
export var getNomeFormatado = function (str, numeros) {
    if (numeros === void 0) { numeros = true; }
    var Ex = /[^a-zA-ZáéíóúàèìòùâêîôûãõçÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃÕÇ\s]/g;
    var ExNumeros = /[^a-zA-Z0-9-/áéíóúàèìòùâêîôûãõçÁÉÍÓÚÀÈÌÒÙÂÊÎÔÛÃÕÇ\s]/g;
    return str.replace(numeros ? ExNumeros : Ex, '').trim();
};
export var validarEmail = function (Email) {
    var RegexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (RegexEmail.test(Email)) {
        return true;
    }
    return false;
};
// Função para criptografar uma string
export var criptografar = function (data) {
    var _a;
    var encrypted = CryptoJS.AES.encrypt(data, (_a = process.env.SECRET_CRIPTO) !== null && _a !== void 0 ? _a : '').toString();
    return encrypted;
};
// Função para descriptografar uma string
export var decriptografar = function (data) {
    var _a;
    var bytes = CryptoJS.AES.decrypt(data, (_a = process.env.SECRET_CRIPTO) !== null && _a !== void 0 ? _a : '');
    var decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
};
export var getVersao = function () {
    var _a;
    return (_a = process.env.VERSAO) !== null && _a !== void 0 ? _a : '';
};
export var getCodigoOfertaFormatada = function (value) {
    switch (true) {
        case value < 10:
            return "0000".concat(value);
        case value < 100:
            return "000".concat(value);
        case value < 1000:
            return "00".concat(value);
        case value < 10000:
            return "0".concat(value);
        default:
            return value;
    }
};
export var getfloatToTimeString = function (HorasFloat) {
    try {
        //if (isNaN(HorasFloat)) return '00:00';
        var hours = Math.floor(HorasFloat);
        var minutes = Math.round((HorasFloat - hours) * 60);
        var paddedHours = String(hours).padStart(2, '0');
        var paddedMinutes = String(minutes).padStart(2, '0');
        return "".concat(paddedHours, ":").concat(paddedMinutes);
    }
    catch (e) {
        //console.log('Erro ao converter horas', e);
        return '00:00';
    }
};
export var ExportarPDF = function (props) { return (_jsx(Tooltip, __assign({ title: 'Exportar PDF', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, __assign({ m: 1, ml: 2 }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }, onClick: props.onClick }, { children: _jsx("img", { src: ImagemPDF, alt: 'PDF', style: { width: 24, height: 24 } }) })) })) }))); };
export var ExportarExcel = function (props) { return (_jsx(Tooltip, __assign({ title: 'Exportar Excel', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(Box, __assign({ m: 1, ml: 0 }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 0, paddingRight: 1, marginTop: -1 }, onClick: props.onClick }, { children: _jsx("img", { src: ImagemExcel, alt: 'Excel', style: { width: 24, height: 24 } }) })) })) }))); };
export var getMascaraMoeda = function (value) {
    if (value == undefined)
        return '';
    if (value === 0 || value === '0')
        return 'R$ 0,00';
    if (typeof value === 'number') {
        return maskCurrency(value);
    }
    else {
        var onlyDigits = value
            .split('')
            .filter(function (s) { return /\d/.test(s); })
            .join('')
            .padStart(3, '0');
        var digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2);
        return maskCurrency(parseFloat(digitsFloat));
    }
};
var maskCurrency = function (valor) {
    var locale = 'pt-BR';
    var currency = 'BRL';
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    }).format(valor);
};
export var getFloatPorcent = function (value) {
    try {
        var float = parseFloat(value.replace(/[^0-9,.-]/g, '').replace(',', '.'));
        return float;
    }
    catch (e) {
        return 0;
    }
};
export var getFloat = function (value) {
    try {
        var sanitizedValue = value
            .replace(/[^0-9,.-]/g, '') // Remove qualquer caractere que não seja número, vírgula, ponto ou traço
            .replace(/\./g, '') // Remove os pontos (separadores de milhar)
            .replace(',', '.'); // Substitui a vírgula pelo ponto (separador decimal)
        var float = parseFloat(sanitizedValue);
        return float;
    }
    catch (e) {
        return 0;
    }
};
export var getMesesDoAno = function () {
    return [
        { value: 1, label: 'Janeiro' },
        { value: 2, label: 'Fevereiro' },
        { value: 3, label: 'Março' },
        { value: 4, label: 'Abril' },
        { value: 5, label: 'Maio' },
        { value: 6, label: 'Junho' },
        { value: 7, label: 'Julho' },
        { value: 8, label: 'Agosto' },
        { value: 9, label: 'Setembro' },
        { value: 10, label: 'Outubro' },
        { value: 11, label: 'Novembro' },
        { value: 12, label: 'Dezembro' },
    ];
};
export var getAnosReferencia = function () {
    var AnoAtual = new Date().getFullYear();
    var ArrayAnos = [{ value: '2024', label: '2024' }];
    if (ArrayAnos.findIndex(function (a) { return a.value == AnoAtual.toString(); }) == -1)
        ArrayAnos.push({ value: AnoAtual.toString(), label: AnoAtual.toString() });
    return ArrayAnos;
};
