var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*eslint no-useless-escape: 0*/
import React, { useState, useEffect, useContext } from 'react';
import { MenuItem } from '@mui/material';
import { MODOS, MENSAGENS, getNomeFormatado, parseDate, getDataFormatada } from '../../../utils';
import { ROUTES, PAGINAS } from '../../../routes';
import ContainerPainel from '../../../components/container_painel';
import AuthContext from '../../../contexts/auth';
import { BoxPrincipal, BoxBotoes } from '../../../components/form_box';
import Loading from '../../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { getOfertaVagaTrabalho, cadastrarOfertaVagaTrabalho, alterarOfertaVagaTrabalho, excluirOfertaVagaTrabalho, getCombosOfertasVagas, getInstituicoesTermosContratos, } from './ofertas_vagas_trabalho.service';
import { STATUS_API } from './ofertas_vagas_trabalho.service';
import { BotaoSalvar, BotaoExcluir, BotaoFechar, BotaoEditar } from '../../../components/botoes';
import InputDados from '../../../components/input_dados';
import { getCidadesAtivos } from '../../../api';
export var OfertasVagasTrabalhosAlterar = function (props) {
    var _a = useContext(AuthContext) || { addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, addMensagem = _a.addMensagem, getAcoesFuncionalidade = _a.getAcoesFuncionalidade;
    var _b = React.useState(getAcoesFuncionalidade(PAGINAS.OFERTAS_VAGAS_TRABALHO)), AcoesFuncionalidade = _b[0], setAcoesFuncionalidade = _b[1];
    var navigate = useNavigate();
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(true), LoadingInicio = _d[0], setLoadingInicio = _d[1];
    var _e = useParams(), id = _e.id, modo = _e.modo;
    var _f = useState({}), OfertaVagaTrabalho = _f[0], setOfertaVagaTrabalho = _f[1];
    var _g = useState([]), InstituicoesDisponiveis = _g[0], setInstituicoesDisponiveis = _g[1];
    var _h = useState(-1), InstituicaoSelecionada = _h[0], setInstituicaoSelecionada = _h[1];
    var _j = useState([]), EstadosDisponiveis = _j[0], setEstadosDisponiveis = _j[1];
    var _k = useState(''), EstadoSelecionado = _k[0], setEstadoSelecionado = _k[1];
    var _l = useState([]), CidadesDisponiveis = _l[0], setCidadesDisponiveis = _l[1];
    var _m = useState(''), CidadeSelecionada = _m[0], setCidadeSelecionada = _m[1];
    var _o = useState([]), TiposContratosDisponiveis = _o[0], setTiposContratosDisponiveis = _o[1];
    var _p = useState(-1), TipoContratoSelecionado = _p[0], setTipoContratoSelecionado = _p[1];
    var _q = useState([]), TermosContratosDisponiveis = _q[0], setTermosContratosDisponiveis = _q[1];
    var _r = useState(-1), TermoContratoSelecionado = _r[0], setTermoContratoSelecionado = _r[1];
    var _s = useState(''), Descricao = _s[0], setDescricao = _s[1];
    var _t = useState(''), DataInicio = _t[0], setDataInicio = _t[1];
    var _u = useState(''), SituacaoOferta = _u[0], setSituacaoOferta = _u[1];
    var _v = useState(0), TotalVagas = _v[0], setTotalVagas = _v[1];
    var DESABILITAR_CAMPOS = modo == MODOS.VISUALIZAR || modo == MODOS.EXCLUIR;
    useEffect(function () {
        //console.log('id', id);
        //console.log('modo', modo);
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, CombosVagas, OfertaVagaTrabalhoAux, instituicoes, estados, cidades, tipos_contratos, descricao, vagas_qtd, situacao_oferta, termo_contrato_id, data_inicio, instituicao_id, estado_id_1, cidade_id_1, tipo_contrato_id, EstadoAux, CidadesAux, CidadeAux, TermosContratosAux, CombosVagas, instituicoes, estados, cidades, tipos_contratos;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!id) return [3 /*break*/, 7];
                    return [4 /*yield*/, Promise.all([getCombosOfertasVagas(), getOfertaVagaTrabalho(id)])];
                case 1:
                    _a = _b.sent(), CombosVagas = _a[0], OfertaVagaTrabalhoAux = _a[1];
                    if (CombosVagas.Status == STATUS_API.ERRO) {
                        alert(CombosVagas.Mensagem);
                        return [2 /*return*/];
                    }
                    instituicoes = CombosVagas.instituicoes, estados = CombosVagas.estados, cidades = CombosVagas.cidades, tipos_contratos = CombosVagas.tipos_contratos;
                    setInstituicoesDisponiveis(instituicoes);
                    setTiposContratosDisponiveis(tipos_contratos);
                    setEstadosDisponiveis(estados);
                    setCidadesDisponiveis(cidades);
                    if (!(OfertaVagaTrabalhoAux.Status == STATUS_API.ERRO)) return [3 /*break*/, 2];
                    alert(OfertaVagaTrabalhoAux.Mensagem);
                    return [2 /*return*/];
                case 2:
                    descricao = OfertaVagaTrabalhoAux.descricao, vagas_qtd = OfertaVagaTrabalhoAux.vagas_qtd, situacao_oferta = OfertaVagaTrabalhoAux.situacao_oferta, termo_contrato_id = OfertaVagaTrabalhoAux.termo_contrato_id, data_inicio = OfertaVagaTrabalhoAux.data_inicio, instituicao_id = OfertaVagaTrabalhoAux.instituicao_id, estado_id_1 = OfertaVagaTrabalhoAux.estado_id, cidade_id_1 = OfertaVagaTrabalhoAux.cidade_id, tipo_contrato_id = OfertaVagaTrabalhoAux.tipo_contrato_id;
                    if (!estado_id_1) return [3 /*break*/, 4];
                    EstadoAux = estados === null || estados === void 0 ? void 0 : estados.find(function (item) { return item.id == estado_id_1; });
                    setEstadoSelecionado(EstadoAux === null || EstadoAux === void 0 ? void 0 : EstadoAux.sigla);
                    return [4 /*yield*/, getCidadesAtivos(EstadoAux === null || EstadoAux === void 0 ? void 0 : EstadoAux.sigla)];
                case 3:
                    CidadesAux = _b.sent();
                    setCidadesDisponiveis(CidadesAux);
                    if (cidade_id_1) {
                        CidadeAux = CidadesAux === null || CidadesAux === void 0 ? void 0 : CidadesAux.find(function (item) { return item.id == cidade_id_1; });
                        setCidadeSelecionada(CidadeAux === null || CidadeAux === void 0 ? void 0 : CidadeAux.codigo_ibge);
                    }
                    _b.label = 4;
                case 4:
                    setDescricao(descricao);
                    setInstituicaoSelecionada(instituicao_id);
                    setTipoContratoSelecionado(tipo_contrato_id);
                    setDataInicio(data_inicio ? getDataFormatada(data_inicio) : '');
                    setSituacaoOferta(situacao_oferta);
                    setTotalVagas(vagas_qtd);
                    if (!termo_contrato_id) return [3 /*break*/, 6];
                    return [4 /*yield*/, getInstituicoesTermosContratos(instituicao_id, tipo_contrato_id)];
                case 5:
                    TermosContratosAux = _b.sent();
                    setTermosContratosDisponiveis(TermosContratosAux);
                    setTermoContratoSelecionado(termo_contrato_id);
                    _b.label = 6;
                case 6: return [3 /*break*/, 9];
                case 7: return [4 /*yield*/, getCombosOfertasVagas()];
                case 8:
                    CombosVagas = _b.sent();
                    if (CombosVagas.Status == STATUS_API.ERRO) {
                        alert(CombosVagas.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        instituicoes = CombosVagas.instituicoes, estados = CombosVagas.estados, cidades = CombosVagas.cidades, tipos_contratos = CombosVagas.tipos_contratos;
                        setInstituicoesDisponiveis(instituicoes);
                        setTiposContratosDisponiveis(tipos_contratos);
                        setEstadosDisponiveis(estados);
                        setCidadesDisponiveis(cidades);
                    }
                    _b.label = 9;
                case 9:
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validarDados = function () {
        if (!Descricao || Descricao.trim() == '') {
            alert("Descri\u00E7\u00E3o ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!InstituicaoSelecionada || InstituicaoSelecionada == -1) {
            alert("Institui\u00E7\u00E3o ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!EstadoSelecionado || EstadoSelecionado == '') {
            alert("Estado ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!CidadeSelecionada || CidadeSelecionada == '') {
            alert("Cidade ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!TermoContratoSelecionado || TermoContratoSelecionado == -1) {
            alert("N\u00FAmero do Termo de Contrato ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!TipoContratoSelecionado || TipoContratoSelecionado == -1) {
            alert("Tipo de Contrato ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        return true;
    };
    var salvarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var EstadoID, CidadeID, Dados, Resp, _a;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!validarDados())
                        return [2 /*return*/];
                    setLoading(true);
                    EstadoID = (_b = EstadosDisponiveis === null || EstadosDisponiveis === void 0 ? void 0 : EstadosDisponiveis.find(function (item) { return item.sigla == EstadoSelecionado; })) === null || _b === void 0 ? void 0 : _b.id;
                    CidadeID = (_c = CidadesDisponiveis === null || CidadesDisponiveis === void 0 ? void 0 : CidadesDisponiveis.find(function (item) { return item.codigo_ibge == CidadeSelecionada; })) === null || _c === void 0 ? void 0 : _c.id;
                    Dados = {
                        descricao: getNomeFormatado(Descricao),
                        instituicao_id: InstituicaoSelecionada,
                        tipo_contrato_id: TipoContratoSelecionado,
                        cidade_id: CidadeID,
                        estado_id: EstadoID,
                        termo_contrato_id: TermoContratoSelecionado,
                        data_inicio: DataInicio ? parseDate(DataInicio) : null,
                        //situacao: Situacao,
                    };
                    _a = modo;
                    switch (_a) {
                        case MODOS.CADASTRAR: return [3 /*break*/, 1];
                        case MODOS.ALTERAR: return [3 /*break*/, 3];
                    }
                    return [3 /*break*/, 5];
                case 1: return [4 /*yield*/, cadastrarOfertaVagaTrabalho(Dados)];
                case 2:
                    Resp = _e.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, alterarOfertaVagaTrabalho(Dados, id !== null && id !== void 0 ? id : '')];
                case 4:
                    Resp = _e.sent();
                    return [3 /*break*/, 5];
                case 5:
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(modo == MODOS.CADASTRAR ? MENSAGENS.REGISTRO_CADASTRADO_SUCESSO : MENSAGENS.REGISTRO_ALTERADO_SUCESSO);
                        if (modo == MODOS.CADASTRAR) {
                            navigate("".concat(ROUTES.OFERTAS_VAGAS_TRABALHO_VAGAS, "/").concat(id !== null && id !== void 0 ? id : (_d = Resp === null || Resp === void 0 ? void 0 : Resp.dados) === null || _d === void 0 ? void 0 : _d.id, "/").concat(MODOS.ALTERAR));
                        }
                        else {
                            navigate(-1);
                        }
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var excluirRegistro = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!confirm(MENSAGENS.EXCLUIR_REGISTRO))
                        return [2 /*return*/];
                    setLoading(true);
                    return [4 /*yield*/, excluirOfertaVagaTrabalho(id !== null && id !== void 0 ? id : '')];
                case 1:
                    Resp = _a.sent();
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        addMensagem(MENSAGENS.REGISTRO_EXCLUIDO_SUCESSO);
                        navigate(ROUTES.OFERTAS_VAGAS_TRABALHO);
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var editarRegistro = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("".concat(ROUTES.OFERTAS_VAGAS_TRABALHO, "/").concat(id, "/").concat(MODOS.ALTERAR));
            return [2 /*return*/];
        });
    }); };
    var onChangeEstado = function (Estado) { return __awaiter(void 0, void 0, void 0, function () {
        var CidadesAux;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setEstadoSelecionado(Estado);
                    return [4 /*yield*/, getCidadesAtivos(Estado)];
                case 1:
                    CidadesAux = _a.sent();
                    setCidadesDisponiveis(CidadesAux);
                    return [2 /*return*/];
            }
        });
    }); };
    var onChangeInstituicao = function (InstituicaoID) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setInstituicaoSelecionada(InstituicaoID);
            if (InstituicaoID && TipoContratoSelecionado) {
                atualizarTermosContratos(InstituicaoID, TipoContratoSelecionado);
            }
            return [2 /*return*/];
        });
    }); };
    var onChangeTipoContrato = function (TipoContratoID) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setTipoContratoSelecionado(TipoContratoID);
            if (InstituicaoSelecionada && TipoContratoID) {
                atualizarTermosContratos(InstituicaoSelecionada, TipoContratoID);
            }
            return [2 /*return*/];
        });
    }); };
    var atualizarTermosContratos = function (InstituicaoID, TipoContratoID) { return __awaiter(void 0, void 0, void 0, function () {
        var TermosContratosAux;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getInstituicoesTermosContratos(InstituicaoID, TipoContratoID)];
                case 1:
                    TermosContratosAux = _a.sent();
                    setTermoContratoSelecionado(-1);
                    setTermosContratosDisponiveis(TermosContratosAux);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(ContainerPainel, __assign({ modo: modo, pagina_acima: PAGINAS.OFERTAS_VAGAS_TRABALHO, link_pagina_acima: ROUTES.OFERTAS_VAGAS_TRABALHO }, { children: _jsxs(BoxPrincipal, { children: [!LoadingInicio && (_jsxs(_Fragment, { children: [_jsx(InputDados, { id: 'id', variant: 'outlined', disabled: true, label: 'C\u00F3digo', value: id, size: 'small' }), _jsx(InputDados, { id: 'descricao', label: 'Descri\u00E7\u00E3o', value: Descricao, onChange: function (event) { return setDescricao(event.target.value); }, variant: 'outlined', size: 'small', fullWidth: true, required: true, multiline: true, rows: 4, disabled: DESABILITAR_CAMPOS }), _jsxs(InputDados, __assign({ select: true, id: 'instituicao', label: 'Institui\u00E7\u00E3o', placeholder: 'Institui\u00E7\u00E3o', value: InstituicaoSelecionada, onChange: function (event) {
                                onChangeInstituicao(event.target.value);
                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), InstituicoesDisponiveis &&
                                    InstituicoesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.dados.razao_social }), item.id)); })] })), _jsxs(InputDados, __assign({ select: true, id: 'tipo_contrato', label: 'Tipo de Contrato', placeholder: 'Tipo de Contrato', value: TipoContratoSelecionado, onChange: function (event) {
                                onChangeTipoContrato(event.target.value);
                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), TiposContratosDisponiveis &&
                                    TiposContratosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })), _jsxs(InputDados, __assign({ select: true, id: 'n_termo_contrato', label: 'N\u00BA Termo Contrato', placeholder: 'N\u00BA Termo Contrato', value: TermoContratoSelecionado, onChange: function (event) {
                                setTermoContratoSelecionado(event.target.value);
                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), TermosContratosDisponiveis &&
                                    TermosContratosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.numero_termo_contrato }), item.id)); })] })), _jsx(InputDados, { id: 'data_inicio', label: 'Data In\u00EDcio', value: DataInicio, mask: '99/99/9999', onChange: function (event) { return setDataInicio(event.target.value); }, variant: 'outlined', size: 'small', fullWidth: true, disabled: DESABILITAR_CAMPOS }), _jsxs(InputDados, __assign({ select: true, id: 'estado', label: 'Estado', placeholder: 'Estado', value: EstadoSelecionado, onChange: function (event) {
                                onChangeEstado(event.target.value);
                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), EstadosDisponiveis &&
                                    EstadosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.sigla }, { children: item.nome }), item.sigla)); })] })), CidadesDisponiveis && (_jsxs(InputDados, __assign({ select: true, id: 'cidade', label: 'Cidade', placeholder: 'Cidade', value: CidadeSelecionada, onChange: function (event) {
                                setCidadeSelecionada(event.target.value);
                            }, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: -1 }, { children: _jsx("em", { children: "Selecione" }) })), CidadesDisponiveis &&
                                    CidadesDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.codigo_ibge }, { children: item.nome }), item.codigo_ibge)); })] }))), _jsx(InputDados, { id: 'quantidade_vagas', label: 'Quantidade de Vagas', value: TotalVagas === null || TotalVagas === void 0 ? void 0 : TotalVagas.toString(), variant: 'outlined', size: 'small', fullWidth: true, disabled: true }), _jsx(InputDados, { id: 'situacao_oferta', label: 'Situa\u00E7\u00E3o da Oferta', value: SituacaoOferta, variant: 'outlined', size: 'small', fullWidth: true, disabled: true }), _jsxs(BoxBotoes, { children: [(MODOS.ALTERAR == modo || MODOS.CADASTRAR == modo) && _jsx(BotaoSalvar, { onClick: salvarDados }), MODOS.EXCLUIR == modo && _jsx(BotaoExcluir, { onClick: excluirRegistro }), MODOS.VISUALIZAR == modo && _jsx(BotaoEditar, { onClick: editarRegistro, disabled: !AcoesFuncionalidade['ALTERAR'] }), _jsx(BotaoFechar, { modo: modo })] })] })), loading && _jsx(Loading, {})] }) })));
};
export default OfertasVagasTrabalhosAlterar;
