var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { Grid, IconButton, MenuItem, Tooltip } from '@mui/material';
import { MENSAGENS, getMesesDoAno, } from '../../../utils';
import ContainerPainel from '../../../components/container_painel';
import AuthContext from '../../../contexts/auth';
import { ROUTES, PAGINAS } from '../../../routes';
import { BotaoAdicionar } from '../../../components/botoes';
import BarraPesquisar from '../../../components/grid_pesquisar';
import GridComp from '../../../components/grid';
import { PaginationModelDefault } from '../../../components/grid';
import { BoxPrincipal, BoxSuperior, BoxGrid } from '../../../components/grid_box';
import { alterarSituacaoLancamentosFolha, getCombosListar, getLancamentosFolhas } from './lancamentos_folhas.service';
import { STATUS_API } from '../../../api';
import { useNavigate } from 'react-router-dom';
import InputDados from '../../../components/input_dados';
import CancelIcon from '@mui/icons-material/Cancel';
export var LancamentosFolhasListar = function (props) {
    var _a = useState(true), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(true), isLoadingInicio = _b[0], setIsLoadingInicio = _b[1];
    var navigate = useNavigate();
    var _c = useState(), ReeducandosDisponiveis = _c[0], setReeducandosDisponiveis = _c[1];
    var _d = useState(''), TextoBusca = _d[0], setTextoBusca = _d[1];
    var _e = useState(-1), BuscaMes = _e[0], setBuscaMes = _e[1];
    var _f = useState(-1), BuscaReeducandoSelecionado = _f[0], setBuscaReeducandoSelecionado = _f[1];
    var _g = useState('-1'), BuscaStatusSelecionado = _g[0], setBuscaStatusSelecionado = _g[1];
    var _h = useState([]), LancamentosFolhas = _h[0], setLancamentosFolhas = _h[1];
    var _j = useState(0), TotalRegistros = _j[0], setTotalRegistros = _j[1];
    var _k = React.useState(PaginationModelDefault), PaginationModel = _k[0], setPaginationModel = _k[1];
    var _l = useContext(AuthContext) || { User: null, getAcoesFuncionalidade: function () { return []; }, addMensagem: function (mensagem) { } }, User = _l.User, getAcoesFuncionalidade = _l.getAcoesFuncionalidade, addMensagem = _l.addMensagem;
    var _m = React.useState(getAcoesFuncionalidade(PAGINAS.LANCAMENTOS_FOLHAS)), AcoesFuncionalidade = _m[0], setAcoesFuncionalidade = _m[1];
    useEffect(function () {
        //chamado quando a paginação é alterada e no inicio
        carregarDados();
        return function () { };
    }, [PaginationModel]);
    useEffect(function () {
        //chamado quando os filtros são alterados e no inicio
        if (isLoadingInicio) {
            setIsLoadingInicio(false);
        }
        else {
            setPaginationModel(function (prevPaginationModel) {
                return __assign(__assign({}, prevPaginationModel), { page: 0 });
            }); //dispara o useEffect que carrega os dados
        }
        return function () { };
    }, [TextoBusca, BuscaMes, BuscaReeducandoSelecionado, BuscaStatusSelecionado]);
    useEffect(function () {
        carregarDadosAuxiliares(); //carrega os combos no inicio
        return function () { };
    }, []);
    var carregarDadosAuxiliares = function () { return __awaiter(void 0, void 0, void 0, function () {
        var ComboListar, reeducandos;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, getCombosListar()];
                case 1:
                    ComboListar = _a.sent();
                    if (ComboListar.Status == STATUS_API.ERRO) {
                        alert(ComboListar.Mensagem);
                    }
                    else {
                        reeducandos = ComboListar.reeducandos;
                        setReeducandosDisponiveis(reeducandos);
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var MesesAno = getMesesDoAno();
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Resposta, dados, total_registros;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, getLancamentosFolhas(TextoBusca == '' ? null : TextoBusca, BuscaMes == -1 ? null : BuscaMes.toString(), BuscaReeducandoSelecionado == -1 ? null : BuscaReeducandoSelecionado.toString(), BuscaStatusSelecionado == '-1' ? null : BuscaStatusSelecionado, PaginationModel)];
                case 1:
                    Resposta = _a.sent();
                    if (Resposta.Status == STATUS_API.ERRO) {
                        alert(Resposta.Mensagem);
                    }
                    else {
                        dados = Resposta.dados, total_registros = Resposta.total_registros;
                        setLancamentosFolhas(dados);
                        setTotalRegistros(total_registros);
                    }
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var COLUNAS_FUNCIONALIDADE = [
        {
            field: 'id',
            headerName: 'Código',
            width: 100,
        },
        {
            field: 'nome',
            headerName: 'Nome',
            width: 300,
        },
        {
            field: 'mes_referencia',
            headerName: 'Mês Referência',
            width: 150,
            valueGetter: function (value, row) { var _a; return "".concat((_a = MesesAno.find(function (item) { return item.value == value; })) === null || _a === void 0 ? void 0 : _a.label); },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
        },
        {
            field: 'situacao',
            headerName: 'Situação',
            width: 150,
        },
    ];
    var OPCOES_FUNCIONALIDADE = {
        ROTA: ROUTES.LANCAMENTOS_FOLHAS,
        VISUALIZAR: AcoesFuncionalidade.VISUALIZAR,
        // ALTERAR: AcoesFuncionalidade.ALTERAR,
        // EXCLUIR: AcoesFuncionalidade.EXCLUIR,
    };
    var inativarParametrosOpcao = function (params) {
        var _a = params.row, id = _a.id, parametros_em_uso = _a.parametros_em_uso, situacao = _a.situacao;
        return (_jsx(_Fragment, { children: situacao == 'Ativo' ? (_jsx(Tooltip, __assign({ title: 'Inativar lançamento', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 1, paddingRight: 0, marginTop: -1 }, onClick: function () {
                        parametros_em_uso ? {} : confirm('Tem certeza que deseja INATIVAR o registro selecionado?') && alterarSituacaoLancamento(id, 'Inativo');
                    } }, { children: _jsx(CancelIcon, { sx: { fontSize: 30 } }) })) }))) : (_jsx(Tooltip, __assign({ title: 'Ativar lançamento', componentsProps: { tooltip: { sx: { fontSize: '0.9rem' } } } }, { children: _jsx(IconButton, __assign({ style: { padding: 0, margin: 1, paddingRight: 0, marginTop: -1 }, onClick: function () {
                        confirm('Tem certeza que deseja ATIVAR o registro selecionado?') && alterarSituacaoLancamento(id, 'Ativo');
                    } }, { children: _jsx(CancelIcon, { sx: { fontSize: 30 } }) })) }))) }));
    };
    var alterarSituacaoLancamento = function (id, situacao) { return __awaiter(void 0, void 0, void 0, function () {
        var Resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, alterarSituacaoLancamentosFolha(id.toString(), situacao)];
                case 1:
                    Resp = _a.sent();
                    setIsLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        alert(situacao == 'ATIVO' ? MENSAGENS.REGISTRO_ATIVADO : MENSAGENS.REGISTRO_INATIVADO);
                        carregarDados();
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(ContainerPainel, __assign({ pagina: PAGINAS.LANCAMENTOS_FOLHAS }, { children: _jsxs(BoxPrincipal, { children: [_jsxs(BoxSuperior, { children: [_jsx(BarraPesquisar, { filtrarGrid: setTextoBusca }), _jsx(BotaoAdicionar, { pagina: ROUTES.LANCAMENTOS_FOLHAS, disabled: !AcoesFuncionalidade['CADASTRAR'] })] }), _jsxs(Grid, __assign({ container: true, spacing: 1, mt: 2, mb: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'mes_busca', label: 'M\u00EAs', placeholder: 'M\u00EAs', value: BuscaMes, onChange: function (event) {
                                    setBuscaMes(event.target.value);
                                } }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), MesesAno &&
                                        MesesAno.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.value }, { children: item.label }), item.value)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'reeducando' // obrigatorio
                                , label: 'Reeducando' // obrigatorio
                                , placeholder: 'Reeducando', value: BuscaReeducandoSelecionado, onChange: function (event) {
                                    setBuscaReeducandoSelecionado(event.target.value);
                                } }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), ReeducandosDisponiveis &&
                                        ReeducandosDisponiveis.map(function (item) { return (_jsx(MenuItem, __assign({ value: item.id }, { children: item.nome }), item.id)); })] })) })), _jsx(Grid, __assign({ item: true, xs: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'status' // obrigatorio
                                , label: 'Status' // obrigatorio
                                , placeholder: 'Status', value: BuscaStatusSelecionado, onChange: function (event) {
                                    setBuscaStatusSelecionado(event.target.value);
                                } }, { children: [_jsx(MenuItem, __assign({ value: '-1' }, { children: "Selecione..." })), _jsx(MenuItem, __assign({ value: 'Aberto' }, { children: "Aberto" })), _jsx(MenuItem, __assign({ value: 'Fechado' }, { children: "Fechado" }))] })) }))] })), _jsx(BoxGrid, { children: _jsx(GridComp, { columns: COLUNAS_FUNCIONALIDADE, rows: LancamentosFolhas, OPCOES_FUNCIONALIDADE: OPCOES_FUNCIONALIDADE, OPCAO_ADICIONAL: inativarParametrosOpcao, TotalRegistros: TotalRegistros, isLoading: isLoading, PaginationModel: PaginationModel, setPaginationModel: setPaginationModel, initialState: {
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'asc' }],
                            },
                        } }) })] }) })));
};
export default LancamentosFolhasListar;
